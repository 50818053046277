import React from 'react'

const SvgBanner = props => (
  <svg
    id='banner_svg__f769e95b-b1a0-49ca-983a-44c2db031a3b'
    x={0}
    y={0}
    viewBox='0 0 480 360'
    xmlSpace='preserve'
    {...props}
  >
    <style>{'.banner_svg__st0{opacity:.5}.banner_svg__st1{fill:#6f8b99}.banner_svg__st86{fill:#d9eaff}'}</style>
    <g className='banner_svg__st0'>
      <path
        className='banner_svg__st1'
        d='M64.23 282.51c-.28 0-.55-.06-.79-.2l-33.98-19.6a.798.798 0 01-.4-.63v-.07c.01-.28.15-.53.4-.67l25.78-14.89c.5-.29 1.12-.29 1.61 0l33.97 19.58c.17.09.31.25.36.44v.26c0 .28-.15.53-.4.67l-25.75 14.91c-.24.13-.52.21-.8.2zm-8.18-35.76c-.2 0-.39.05-.56.14l-25.78 14.89c-.08.05-.14.14-.14.23-.01.11.05.21.14.26l33.97 19.58c.34.2.77.2 1.11 0l25.78-14.86c.07-.05.13-.12.14-.2.01-.1-.05-.2-.14-.25L56.6 246.89c-.17-.1-.36-.14-.55-.14z'
      />
    </g>
    <g className='banner_svg__st0'>
      <path
        className='banner_svg__st1'
        d='M412.42 359.81c-.28 0-.56-.07-.8-.22l-33.97-19.61a.815.815 0 01-.4-.64v-.06c0-.28.15-.53.4-.67l25.78-14.85c.5-.29 1.12-.29 1.61 0l33.97 19.58c.17.1.3.25.36.44v.25c-.01.28-.15.53-.4.68l-25.78 14.89c-.23.14-.5.21-.77.21zm-8.18-35.76c-.2 0-.39.05-.56.14l-25.74 14.89c-.08.05-.14.14-.14.23-.01.11.05.21.14.26l33.97 19.58c.34.2.77.2 1.11 0l25.78-14.89c.07-.05.13-.12.14-.2a.248.248 0 00-.14-.25l-33.97-19.58c-.17-.12-.38-.18-.59-.18z'
      />
    </g>
    <g className='banner_svg__st0'>
      <path
        className='banner_svg__st1'
        d='M113.67 284.91c-.18 0-.36-.05-.51-.14l-18.92-10.92a.556.556 0 01-.18-.78c.05-.07.11-.14.18-.18l14.42-8.31c.32-.18.69-.18 1.01 0l18.92 10.92c.18.1.28.29.28.5-.01.19-.11.37-.27.47l-14.42 8.31c-.16.09-.33.13-.51.13zm-19.21-11.5l18.92 10.93c.15.09.35.09.5 0l14.43-8.34-18.92-10.93a.497.497 0 00-.5 0l-14.42 8.31-.14.3.13-.27z'
      />
    </g>
    <g className='banner_svg__st0'>
      <path
        className='banner_svg__st1'
        d='M441.69 104.92c-.18 0-.35-.05-.5-.14l-18.92-10.92a.594.594 0 01-.28-.45c-.01-.21.1-.41.28-.51l14.42-8.31c.32-.18.69-.18 1.01 0l18.92 10.92c.18.1.28.29.28.5-.01.2-.11.37-.28.47l-14.42 8.31c-.16.09-.33.13-.51.13zm-19.21-11.51l18.92 10.93c.15.09.35.09.5 0L456.33 96l-18.91-10.97a.497.497 0 00-.5 0l-14.42 8.31-.13.3.11-.23z'
      />
    </g>
    <g className='banner_svg__st0'>
      <path
        className='banner_svg__st1'
        d='M20.09 134.97c-.18 0-.35-.04-.5-.13L.66 123.91a.553.553 0 01-.28-.45c-.01-.21.1-.41.28-.51l14.42-8.31c.32-.18.7-.18 1.02 0l18.92 10.93c.17.1.28.29.28.5-.01.19-.12.37-.28.47l-14.42 8.31c-.15.08-.33.13-.51.12zm-19.2-11.5l18.92 10.92c.15.09.35.09.5 0l14.42-8.34V126l-18.92-10.92a.497.497 0 00-.5 0l-14.42 8.3-.14.32.14-.23z'
      />
    </g>
    <g className='banner_svg__st0'>
      <path
        className='banner_svg__st1'
        d='M288.16 263.38c-.18 0-.35-.05-.5-.14l-18.92-10.92a.553.553 0 01-.28-.45c-.01-.21.1-.41.28-.51l14.42-8.31c.32-.18.7-.18 1.02 0l18.92 10.93c.18.1.29.29.28.5-.01.19-.12.37-.28.47l-14.42 8.3c-.16.09-.34.13-.52.13zm-19.2-11.49l18.92 10.92c.15.09.35.09.5 0l14.42-8.34v-.05l-18.92-10.93a.497.497 0 00-.5 0l-14.42 8.31-.13.3.13-.21z'
      />
    </g>
    <g className='banner_svg__st0'>
      <path
        className='banner_svg__st1'
        d='M245.03 161.18c-.18 0-.35-.05-.5-.14l-18.92-10.93a.565.565 0 01-.21-.75c.05-.09.12-.15.21-.21l14.42-8.31c.32-.18.7-.18 1.02 0l18.92 10.92c.17.1.28.29.28.5-.01.2-.11.37-.28.47l-14.42 8.31c-.16.1-.34.14-.52.14zm-19.2-11.51l18.92 10.93c.15.09.35.09.5 0l14.42-8.34c.01-.02.01-.03 0-.05l-18.92-10.92a.497.497 0 00-.5 0l-14.42 8.31-.13.3.13-.23z'
      />
    </g>
    <linearGradient
      id='banner_svg__SVGID_1_'
      gradientUnits='userSpaceOnUse'
      x1={509.742}
      y1={-164.015}
      x2={-206.455}
      y2={96.234}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#00bfa5' />
      <stop offset={0.5} stopColor='#6dd9ca' />
      <stop offset={1} stopColor='#b5f2ea' />
    </linearGradient>
    <path
      d='M200.14 300.24l-.47-.9 9.76-5.1a6.45 6.45 0 003.46-5.72v-27.04c0-2.68 1.43-5.15 3.75-6.48l53.22-30.64c1.97-1.14 4.22-1.74 6.5-1.74h24.09c2.13 0 4.21-.56 6.05-1.63l145.93-85a2.87 2.87 0 001.02-3.92c-.26-.44-.64-.81-1.09-1.06l-49.23-26.68.5-.9 49.18 26.69a3.873 3.873 0 011.56 5.25c-.34.63-.85 1.14-1.46 1.5l-145.93 85a13.044 13.044 0 01-6.56 1.8h-24.08c-2.1 0-4.17.55-5.99 1.6l-53.22 30.64a6.492 6.492 0 00-3.23 5.6v27.04a7.444 7.444 0 01-4.01 6.62l-9.75 5.07z'
      fill='url(#banner_svg__SVGID_1_)'
    />
    <linearGradient
      id='banner_svg__SVGID_2_'
      gradientUnits='userSpaceOnUse'
      x1={535.571}
      y1={-92.956}
      x2={-180.626}
      y2={167.294}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#00bfa5' />
      <stop offset={0.5} stopColor='#6dd9ca' />
      <stop offset={1} stopColor='#b5f2ea' />
    </linearGradient>
    <path
      d='M203.93 306.64l-.51-.9 25.02-14.42a12.27 12.27 0 016.11-1.64h22.98c1.96 0 3.88-.51 5.57-1.48l20.54-11.81a8.859 8.859 0 018.8 0l24.69 14.12a7.865 7.865 0 007.8 0l34.82-20.11.51.9-34.82 20.11a8.847 8.847 0 01-8.82 0l-24.69-14.12a7.846 7.846 0 00-7.79 0l-20.54 11.77a12.243 12.243 0 01-6.08 1.62h-22.98c-1.96 0-3.89.51-5.6 1.5l-25.01 14.46z'
      fill='url(#banner_svg__SVGID_2_)'
    />
    <linearGradient
      id='banner_svg__SVGID_3_'
      gradientUnits='userSpaceOnUse'
      x1={493.679}
      y1={-208.222}
      x2={-222.518}
      y2={52.028}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#00bfa5' />
      <stop offset={0.5} stopColor='#6dd9ca' />
      <stop offset={1} stopColor='#b5f2ea' />
    </linearGradient>
    <path
      d='M194.79 295.74l-.56-.86 5.57-3.6a5.174 5.174 0 002.35-4.33v-21.03a5.18 5.18 0 00-2.52-4.43L60.85 179.01a6.206 6.206 0 01-3.02-5.32v-18.33a6.16 6.16 0 013.25-5.41l89.42-48.19.49.9-89.43 48.16c-1.66.9-2.69 2.62-2.7 4.51v18.33c.01 1.82.96 3.5 2.52 4.43l138.78 82.47a6.24 6.24 0 013.02 5.32v21.02c.01 2.1-1.05 4.06-2.82 5.19l-5.57 3.65z'
      fill='url(#banner_svg__SVGID_3_)'
    />
    <linearGradient
      id='banner_svg__SVGID_4_'
      gradientUnits='userSpaceOnUse'
      x1={114.623}
      y1={-131.574}
      x2={114.623}
      y2={-131.574}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#007aff' />
      <stop offset={0.5} stopColor='#009cff' />
      <stop offset={1} stopColor='#d9eaff' />
    </linearGradient>
    <linearGradient
      id='banner_svg__SVGID_5_'
      gradientUnits='userSpaceOnUse'
      x1={68.834}
      y1={-132.827}
      x2={607.383}
      y2={-132.827}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#00349b' />
      <stop offset={0.5} stopColor='#005cff' />
      <stop offset={1} stopColor='#007aff' />
    </linearGradient>
    <path
      d='M243.08 156.57l-69.49-39.96a3.615 3.615 0 00-3.6 0l-68.14 39.35c-.41.23-.67.65-.7 1.12v.11c-.01.5.26.97.7 1.23l69.49 39.96c1.12.64 2.49.64 3.6 0l68.14-39.35c.42-.23.69-.68.7-1.17.03-.53-.24-1.03-.7-1.29z'
      opacity={0.3}
      fill='url(#banner_svg__SVGID_5_)'
    />
    <linearGradient
      id='banner_svg__SVGID_6_'
      gradientUnits='userSpaceOnUse'
      x1={100.555}
      y1={-142.19}
      x2={639.096}
      y2={-142.19}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#007aff' />
      <stop offset={0.5} stopColor='#009cff' />
      <stop offset={1} stopColor='#d9eaff' />
    </linearGradient>
    <path
      d='M242.49 146.88l-68.9-39.65a3.615 3.615 0 00-3.6 0l-67.48 39h-1.35v1.61c0 .5.27.97.7 1.23l69.49 39.95c1.12.64 2.49.64 3.6 0l68.14-39.34c.42-.24.69-.69.7-1.18v-1.61l-1.3-.01z'
      fill='url(#banner_svg__SVGID_6_)'
    />
    <linearGradient
      id='banner_svg__SVGID_7_'
      gradientUnits='userSpaceOnUse'
      x1={-96.324}
      y1={-143.772}
      x2={442.226}
      y2={-143.772}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#007aff' />
      <stop offset={0.5} stopColor='#009cff' />
      <stop offset={1} stopColor='#d9eaff' />
    </linearGradient>
    <path
      d='M243.08 145.6l-69.49-39.92a3.615 3.615 0 00-3.6 0l-68.14 39.34c-.41.23-.67.65-.7 1.12v.1c-.01.5.26.97.7 1.23l69.49 39.95c1.12.64 2.49.64 3.6 0l68.14-39.37a1.423 1.423 0 000-2.46v.01z'
      fill='url(#banner_svg__SVGID_7_)'
    />
    <linearGradient
      id='banner_svg__SVGID_8_'
      gradientUnits='userSpaceOnUse'
      x1={-22.274}
      y1={-136.825}
      x2={252.872}
      y2={-147.819}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#00349b' />
      <stop offset={0.5} stopColor='#005cff' />
      <stop offset={1} stopColor='#007aff' />
    </linearGradient>
    <path
      d='M235.19 144.89l-61.73-35.5a3.19 3.19 0 00-3.19 0l-60.53 34.95c-.36.21-.59.58-.63.99v.11c0 .45.23.87.63 1.08l61.73 35.5c.98.58 2.21.58 3.19 0l60.53-34.95c.6-.34.81-1.11.48-1.7a1.3 1.3 0 00-.48-.48z'
      opacity={0.2}
      fill='url(#banner_svg__SVGID_8_)'
    />
    <linearGradient
      id='banner_svg__SVGID_9_'
      gradientUnits='userSpaceOnUse'
      x1={120.931}
      y1={-143.7}
      x2={223.982}
      y2={-143.7}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#00bfa5' />
      <stop offset={0.5} stopColor='#6dd9ca' />
      <stop offset={1} stopColor='#b5f2ea' />
    </linearGradient>
    <path
      d='M172.95 176.72c-.54 0-1.07-.14-1.54-.41l-49.71-28.59c-.47-.27-.77-.78-.77-1.32v-.17c.05-.49.32-.93.76-1.17l48.74-28.14a3.13 3.13 0 013.08 0l49.71 28.59c.5.28.78.82.76 1.39-.01.52-.3 1-.76 1.26l-48.74 28.14c-.47.27-.99.41-1.53.42zm-.97-59.17c-.36 0-.71.09-1.03.27l-48.74 28.14c-.25.13-.35.43-.23.68.05.1.13.17.23.23l49.71 28.58c.63.37 1.42.37 2.05 0l48.74-28.14a.48.48 0 00.25-.41.475.475 0 00-.24-.46l-49.71-28.59c-.31-.19-.66-.29-1.03-.3z'
      fill='url(#banner_svg__SVGID_9_)'
    />
    <linearGradient
      id='banner_svg__SVGID_10_'
      gradientUnits='userSpaceOnUse'
      x1={12.971}
      y1={-155.757}
      x2={309.465}
      y2={-170.338}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#007aff' />
      <stop offset={0.5} stopColor='#009cff' />
      <stop offset={1} stopColor='#d9eaff' />
    </linearGradient>
    <path
      d='M235.82 101.7V119c-.02.42-.25.82-.62 1.03l-60.53 34.95c-.98.57-2.21.57-3.19 0l-61.73-35.5c-.41-.23-.66-.68-.63-1.14v-17.22c0 .45.23.87.63 1.08l61.73 35.5c.98.58 2.21.58 3.19 0l60.53-34.95c.38-.22.62-.62.62-1.05z'
      fill='url(#banner_svg__SVGID_10_)'
    />
    <linearGradient
      id='banner_svg__SVGID_11_'
      gradientUnits='userSpaceOnUse'
      x1={-22.274}
      y1={-181.157}
      x2={252.871}
      y2={-192.151}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#007aff' />
      <stop offset={0.5} stopColor='#009cff' />
      <stop offset={1} stopColor='#d9eaff' />
    </linearGradient>
    <path
      d='M235.19 100.56l-61.73-35.5c-.98-.58-2.21-.58-3.19 0l-60.53 34.95c-.36.21-.59.58-.63.99v.11c0 .45.23.87.63 1.08l61.73 35.5c.98.58 2.21.58 3.19 0l60.53-34.95c.6-.34.81-1.11.48-1.7a1.3 1.3 0 00-.48-.48z'
      fill='url(#banner_svg__SVGID_11_)'
    />
    <linearGradient
      id='banner_svg__SVGID_12_'
      gradientUnits='userSpaceOnUse'
      x1={243.121}
      y1={-181.811}
      x2={-17.345}
      y2={-180.54}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#00349b' />
      <stop offset={0.5} stopColor='#005cff' />
      <stop offset={1} stopColor='#007aff' />
    </linearGradient>
    <path
      d='M232.06 83.98v16.29c-.02.4-.23.77-.59.96l-56.94 32.87c-.93.53-2.07.53-3 0l-58.06-33.34c-.39-.22-.61-.63-.59-1.07V83.47c0 .42.23.82.59 1.03l58.06 33.34c.93.54 2.07.54 3 0l56.93-32.89c.36-.21.58-.58.59-.99l.01.02z'
      fill='url(#banner_svg__SVGID_12_)'
    />
    <linearGradient
      id='banner_svg__SVGID_13_'
      gradientUnits='userSpaceOnUse'
      x1={12.97}
      y1={-180.81}
      x2={309.465}
      y2={-195.391}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#007aff' />
      <stop offset={0.5} stopColor='#009cff' />
      <stop offset={1} stopColor='#d9eaff' />
    </linearGradient>
    <path
      d='M235.82 76.65v17.31c-.02.42-.25.82-.62 1.03l-60.54 34.93c-.98.57-2.21.57-3.19 0l-61.73-35.5c-.41-.23-.66-.68-.63-1.14V76.06c-.01.45.23.87.63 1.09l61.73 35.5c.99.57 2.2.57 3.19 0l60.53-34.9c.38-.21.62-.61.63-1.05v-.05z'
      fill='url(#banner_svg__SVGID_13_)'
    />
    <linearGradient
      id='banner_svg__SVGID_14_'
      gradientUnits='userSpaceOnUse'
      x1={-22.274}
      y1={-206.212}
      x2={252.871}
      y2={-217.197}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#007aff' />
      <stop offset={0.5} stopColor='#009cff' />
      <stop offset={1} stopColor='#d9eaff' />
    </linearGradient>
    <path
      d='M235.19 75.52l-61.73-35.51a3.19 3.19 0 00-3.19 0l-60.53 34.96c-.36.21-.59.58-.63.99v.1c-.01.45.23.87.63 1.09l61.73 35.5c.99.57 2.2.57 3.19 0l60.53-34.9c.6-.34.81-1.11.48-1.7a1.3 1.3 0 00-.48-.48v-.05z'
      fill='url(#banner_svg__SVGID_14_)'
    />
    <linearGradient
      id='banner_svg__SVGID_15_'
      gradientUnits='userSpaceOnUse'
      x1={242.995}
      y1={-206.942}
      x2={-17.471}
      y2={-205.68}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#00349b' />
      <stop offset={0.5} stopColor='#005cff' />
      <stop offset={1} stopColor='#007aff' />
    </linearGradient>
    <path
      d='M232.06 58.86v16.28c-.02.4-.23.77-.59.96l-56.94 32.87c-.93.54-2.07.54-3 0l-58.06-33.34c-.39-.23-.61-.64-.59-1.08V58.33c0 .42.23.82.59 1.03l58.1 33.36c.93.53 2.07.53 3 0l56.94-32.87c.35-.2.58-.58.59-.98 0-.01-.04-.01-.04-.01z'
      fill='url(#banner_svg__SVGID_15_)'
    />
    <linearGradient
      id='banner_svg__SVGID_16_'
      gradientUnits='userSpaceOnUse'
      x1={12.97}
      y1={-205.939}
      x2={309.464}
      y2={-220.52}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#007aff' />
      <stop offset={0.5} stopColor='#009cff' />
      <stop offset={1} stopColor='#d9eaff' />
    </linearGradient>
    <path
      d='M235.82 51.53v17.3c-.02.42-.25.82-.62 1.03l-60.54 34.92c-.99.57-2.2.57-3.19 0l-61.73-35.5c-.41-.23-.66-.68-.63-1.14v-17.2c0 .45.24.87.63 1.09l61.73 35.49c.98.58 2.21.58 3.19 0l60.53-34.95c.39-.2.62-.61.63-1.04z'
      fill='url(#banner_svg__SVGID_16_)'
    />
    <linearGradient
      id='banner_svg__SVGID_17_'
      gradientUnits='userSpaceOnUse'
      x1={-22.275}
      y1={-231.34}
      x2={252.871}
      y2={-242.334}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#007aff' />
      <stop offset={0.5} stopColor='#009cff' />
      <stop offset={1} stopColor='#d9eaff' />
    </linearGradient>
    <path
      d='M235.19 50.39l-61.73-35.5a3.19 3.19 0 00-3.19 0L109.74 49.8c-.36.21-.59.59-.63 1v.1c0 .45.24.87.63 1.09l61.73 35.49c.98.58 2.21.58 3.19 0l60.53-34.95c.6-.34.81-1.11.48-1.7a1.3 1.3 0 00-.48-.48v.04z'
      fill='url(#banner_svg__SVGID_17_)'
    />
    <linearGradient
      id='banner_svg__SVGID_18_'
      gradientUnits='userSpaceOnUse'
      x1={126.66}
      y1={-208.727}
      x2={166.04}
      y2={-208.727}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#fbd242' />
      <stop offset={1} stopColor='#f56329' />
    </linearGradient>
    <path
      d='M165.57 93.11c-.08 0-.17-.02-.24-.06L126.9 70.96a.478.478 0 01-.17-.66c.14-.23.42-.3.65-.17l38.43 22.09c.23.14.31.42.18.66-.1.14-.25.23-.42.23z'
      fill='url(#banner_svg__SVGID_18_)'
    />
    <linearGradient
      id='banner_svg__SVGID_19_'
      gradientUnits='userSpaceOnUse'
      x1={126.66}
      y1={-183.36}
      x2={166.04}
      y2={-183.36}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#fbd242' />
      <stop offset={1} stopColor='#f56329' />
    </linearGradient>
    <path
      d='M165.57 118.48c-.08 0-.17-.02-.24-.06L126.9 96.33a.478.478 0 01-.17-.66c.14-.23.42-.3.65-.17l38.43 22.09c.23.14.31.42.18.66-.1.14-.25.23-.42.23z'
      fill='url(#banner_svg__SVGID_19_)'
    />
    <linearGradient
      id='banner_svg__SVGID_20_'
      gradientUnits='userSpaceOnUse'
      x1={126.657}
      y1={-159.509}
      x2={166.04}
      y2={-159.509}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#fbd242' />
      <stop offset={1} stopColor='#f56329' />
    </linearGradient>
    <path
      d='M165.57 142.33c-.08 0-.17-.02-.24-.06l-38.43-22.1a.464.464 0 01-.18-.64s0-.01.01-.01c.14-.23.42-.31.65-.18l38.43 22.1c.23.13.31.42.18.65-.1.14-.25.24-.42.24z'
      fill='url(#banner_svg__SVGID_20_)'
    />
    <g>
      <linearGradient
        id='banner_svg__SVGID_21_'
        gradientUnits='userSpaceOnUse'
        x1={245.103}
        y1={-147.521}
        x2={783.652}
        y2={-147.521}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#00349b' />
        <stop offset={0.5} stopColor='#005cff' />
        <stop offset={1} stopColor='#007aff' />
      </linearGradient>
      <path
        d='M419.35 141.88l-69.49-39.96a3.615 3.615 0 00-3.6 0l-68.13 39.34c-.41.23-.68.65-.71 1.12v.12c-.01.5.27.98.71 1.23l69.48 39.95c1.12.64 2.49.64 3.6 0l68.14-39.34a1.423 1.423 0 000-2.46z'
        opacity={0.3}
        fill='url(#banner_svg__SVGID_21_)'
      />
      <linearGradient
        id='banner_svg__SVGID_22_'
        gradientUnits='userSpaceOnUse'
        x1={276.825}
        y1={-156.879}
        x2={815.365}
        y2={-156.879}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M418.76 132.19l-68.9-39.65a3.615 3.615 0 00-3.6 0l-67.43 39h-1.35v1.6c-.01.5.27.98.71 1.23l69.48 39.96c1.12.64 2.49.64 3.6 0l68.14-39.35c.42-.23.69-.68.7-1.17v-1.61l-1.35-.01z'
        fill='url(#banner_svg__SVGID_22_)'
      />
      <linearGradient
        id='banner_svg__SVGID_23_'
        gradientUnits='userSpaceOnUse'
        x1={79.955}
        y1={-158.488}
        x2={618.495}
        y2={-158.488}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M419.35 130.91l-69.49-39.96a3.615 3.615 0 00-3.6 0l-68.13 39.34c-.41.23-.68.65-.71 1.12v.12c0 .5.27.97.71 1.23l69.48 39.95c1.12.64 2.49.64 3.6 0l68.14-39.34a1.423 1.423 0 000-2.46z'
        fill='url(#banner_svg__SVGID_23_)'
      />
      <linearGradient
        id='banner_svg__SVGID_24_'
        gradientUnits='userSpaceOnUse'
        x1={153.994}
        y1={-151.533}
        x2={429.14}
        y2={-162.518}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#00349b' />
        <stop offset={0.5} stopColor='#005cff' />
        <stop offset={1} stopColor='#007aff' />
      </linearGradient>
      <path
        d='M411.47 130.2l-61.74-35.5a3.19 3.19 0 00-3.19 0l-60.51 34.96c-.36.21-.59.58-.62.99v.1c0 .44.24.85.62 1.06l61.73 35.5c.99.57 2.2.57 3.19 0l60.54-34.95c.38-.22.61-.61.62-1.05.01-.45-.24-.88-.64-1.11z'
        opacity={0.2}
        fill='url(#banner_svg__SVGID_24_)'
      />
      <linearGradient
        id='banner_svg__SVGID_25_'
        gradientUnits='userSpaceOnUse'
        x1={297.2}
        y1={-158.379}
        x2={400.251}
        y2={-158.379}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#00bfa5' />
        <stop offset={0.5} stopColor='#6dd9ca' />
        <stop offset={1} stopColor='#b5f2ea' />
      </linearGradient>
      <path
        d='M349.22 162.03c-.54 0-1.07-.14-1.54-.41l-49.71-28.58c-.48-.27-.77-.78-.77-1.32v-.15c.05-.49.32-.92.75-1.16l48.74-28.14a3.08 3.08 0 013.08 0l49.71 28.59c.5.28.79.81.77 1.38a1.5 1.5 0 01-.77 1.27l-48.74 28.13c-.46.25-.99.39-1.52.39zm-.97-59.17c-.36 0-.71.1-1.03.28l-48.74 28.14c-.14.08-.23.23-.25.4-.02.19.08.38.25.47l49.73 28.59c.63.37 1.42.37 2.05 0l48.75-28.14c.15-.08.24-.23.24-.41a.475.475 0 00-.24-.46l-49.73-28.59a1.97 1.97 0 00-1.03-.28z'
        fill='url(#banner_svg__SVGID_25_)'
      />
      <linearGradient
        id='banner_svg__SVGID_26_'
        gradientUnits='userSpaceOnUse'
        x1={189.239}
        y1={-170.446}
        x2={485.734}
        y2={-185.027}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M412.1 87.01v17.3c-.03.42-.25.81-.62 1.03l-60.57 34.95c-.98.57-2.21.57-3.19 0l-61.69-35.51c-.41-.23-.65-.67-.62-1.14V86.42c-.01.45.23.87.63 1.09l61.73 35.5c.99.57 2.2.57 3.19 0l60.51-34.95c.38-.21.61-.62.63-1.05z'
        fill='url(#banner_svg__SVGID_26_)'
      />
      <linearGradient
        id='banner_svg__SVGID_27_'
        gradientUnits='userSpaceOnUse'
        x1={153.995}
        y1={-195.847}
        x2={429.14}
        y2={-206.833}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M411.47 85.85l-61.74-35.48a3.19 3.19 0 00-3.19 0l-60.51 34.96c-.36.21-.59.58-.62.99v.1c-.01.45.23.87.63 1.09l61.73 35.5c.99.57 2.2.57 3.19 0l60.51-34.95c.38-.22.61-.62.62-1.05.03-.47-.22-.92-.62-1.16z'
        fill='url(#banner_svg__SVGID_27_)'
      />
      <linearGradient
        id='banner_svg__SVGID_28_'
        gradientUnits='userSpaceOnUse'
        x1={419.39}
        y1={-196.536}
        x2={158.924}
        y2={-195.266}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#00349b' />
        <stop offset={0.5} stopColor='#005cff' />
        <stop offset={1} stopColor='#007aff' />
      </linearGradient>
      <path
        d='M408.33 69.3v16.28c-.02.4-.24.77-.59.96l-56.94 32.87c-.93.53-2.07.53-3 0l-58.07-33.38c-.39-.22-.61-.63-.59-1.07V68.73c0 .42.23.82.59 1.03l58.06 33.34c.93.54 2.07.54 3 0l56.94-32.87c.35-.2.58-.58.59-.98l.01.05z'
        fill='url(#banner_svg__SVGID_28_)'
      />
      <linearGradient
        id='banner_svg__SVGID_29_'
        gradientUnits='userSpaceOnUse'
        x1={189.24}
        y1={-195.487}
        x2={485.734}
        y2={-210.067}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M412.1 61.96v17.3c-.03.42-.25.81-.62 1.03l-60.57 34.95c-.98.58-2.21.58-3.19 0l-61.69-35.5c-.41-.23-.65-.67-.62-1.14V61.38c-.01.45.23.87.63 1.09l61.73 35.5c.99.57 2.2.57 3.19 0l60.51-34.96c.38-.22.61-.61.63-1.05z'
        fill='url(#banner_svg__SVGID_29_)'
      />
      <linearGradient
        id='banner_svg__SVGID_30_'
        gradientUnits='userSpaceOnUse'
        x1={153.995}
        y1={-220.891}
        x2={429.14}
        y2={-231.876}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M411.47 60.83l-61.74-35.5a3.19 3.19 0 00-3.19 0l-60.51 34.96c-.36.21-.59.58-.62.99v.1c-.01.45.23.87.63 1.09l61.73 35.5c.99.57 2.2.57 3.19 0l60.51-34.96c.38-.22.61-.61.62-1.05.02-.46-.22-.9-.62-1.13z'
        fill='url(#banner_svg__SVGID_30_)'
      />
      <linearGradient
        id='banner_svg__SVGID_31_'
        gradientUnits='userSpaceOnUse'
        x1={419.264}
        y1={-221.633}
        x2={158.798}
        y2={-220.363}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#00349b' />
        <stop offset={0.5} stopColor='#005cff' />
        <stop offset={1} stopColor='#007aff' />
      </linearGradient>
      <path
        d='M408.33 44.18v16.27c-.02.4-.23.77-.59.96L350.8 94.28c-.93.53-2.07.53-3 0l-58.06-33.34c-.39-.22-.61-.63-.59-1.07V43.65c0 .42.23.82.59 1.03l58.06 33.34c.93.54 2.07.54 3 0l56.94-32.87c.36-.19.58-.57.59-.97z'
        fill='url(#banner_svg__SVGID_31_)'
      />
      <linearGradient
        id='banner_svg__SVGID_32_'
        gradientUnits='userSpaceOnUse'
        x1={189.24}
        y1={-220.597}
        x2={485.735}
        y2={-235.178}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M412.1 36.85v17.3c-.01.44-.25.85-.63 1.06l-60.56 34.9c-.98.57-2.21.57-3.19 0l-61.69-35.49c-.41-.23-.66-.68-.62-1.14V36.29c0 .45.23.87.63 1.08l61.73 35.5c.98.58 2.21.58 3.19 0l60.51-34.97c.38-.22.61-.62.63-1.05z'
        fill='url(#banner_svg__SVGID_32_)'
      />
      <linearGradient
        id='banner_svg__SVGID_33_'
        gradientUnits='userSpaceOnUse'
        x1={153.995}
        y1={-245.991}
        x2={429.141}
        y2={-256.986}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M411.47 35.71L349.73.24c-.98-.58-2.21-.58-3.19 0l-60.51 34.92c-.36.21-.59.58-.62.99v.11c0 .45.23.87.63 1.08l61.73 35.5c.98.58 2.21.58 3.19 0l60.51-34.95a1.27 1.27 0 000-2.18z'
        fill='url(#banner_svg__SVGID_33_)'
      />
      <linearGradient
        id='banner_svg__SVGID_34_'
        gradientUnits='userSpaceOnUse'
        x1={302.902}
        y1={-223.411}
        x2={342.283}
        y2={-223.411}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path
        d='M341.84 78.43c-.08 0-.17-.02-.24-.06l-38.44-22.1a.468.468 0 01-.19-.64l.01-.01c.14-.23.42-.31.65-.18l38.43 22.1c.23.13.31.42.18.65-.1.14-.24.23-.4.24z'
        fill='url(#banner_svg__SVGID_34_)'
      />
      <linearGradient
        id='banner_svg__SVGID_35_'
        gradientUnits='userSpaceOnUse'
        x1={302.931}
        y1={-198.048}
        x2={342.335}
        y2={-198.048}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path
        d='M341.84 103.8c-.08 0-.17-.02-.24-.06l-38.44-22.09c-.24-.11-.34-.4-.23-.63s.4-.34.63-.23c.02.01.05.03.06.04l38.43 22.09c.23.13.31.42.18.65-.09.13-.23.22-.39.23z'
        fill='url(#banner_svg__SVGID_35_)'
      />
      <linearGradient
        id='banner_svg__SVGID_36_'
        gradientUnits='userSpaceOnUse'
        x1={302.902}
        y1={-174.189}
        x2={342.283}
        y2={-174.189}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path
        d='M341.84 127.65c-.08 0-.17-.03-.24-.06l-38.44-22.1a.468.468 0 01-.19-.64l.01-.01c.13-.23.42-.31.65-.18l38.43 22.1c.23.13.31.42.18.65-.1.14-.24.23-.4.24z'
        fill='url(#banner_svg__SVGID_36_)'
      />
    </g>
    <g>
      <linearGradient
        id='banner_svg__SVGID_37_'
        gradientUnits='userSpaceOnUse'
        x1={304.698}
        y1={19.34}
        x2={843.247}
        y2={19.34}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#00349b' />
        <stop offset={0.5} stopColor='#005cff' />
        <stop offset={1} stopColor='#007aff' />
      </linearGradient>
      <path
        d='M478.94 308.74l-69.45-39.95a3.615 3.615 0 00-3.6 0l-68.13 39.34c-.41.23-.68.66-.71 1.13v.09c-.01.5.27.98.71 1.23l69.48 39.95c1.12.64 2.49.64 3.6 0l68.14-39.34c.42-.24.69-.69.7-1.18.01-.53-.28-1.03-.74-1.27z'
        opacity={0.3}
        fill='url(#banner_svg__SVGID_37_)'
      />
      <linearGradient
        id='banner_svg__SVGID_38_'
        gradientUnits='userSpaceOnUse'
        x1={336.419}
        y1={9.964}
        x2={874.959}
        y2={9.964}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M478.35 299.05l-68.9-39.65a3.615 3.615 0 00-3.6 0l-67.49 38.97h-1.35v1.6c-.01.5.27.98.71 1.23l69.48 39.96c1.12.64 2.49.64 3.6 0l68.14-39.35c.42-.23.69-.68.7-1.17v-1.61l-1.29.02z'
        fill='url(#banner_svg__SVGID_38_)'
      />
      <linearGradient
        id='banner_svg__SVGID_39_'
        gradientUnits='userSpaceOnUse'
        x1={139.54}
        y1={8.382}
        x2={678.089}
        y2={8.382}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M478.94 297.78l-69.45-39.96a3.615 3.615 0 00-3.6 0l-68.13 39.34c-.41.23-.68.65-.71 1.12v.12c0 .5.27.97.71 1.23l69.48 39.95c1.12.64 2.49.64 3.6 0l68.14-39.34a1.423 1.423 0 000-2.46h-.04z'
        fill='url(#banner_svg__SVGID_39_)'
      />
      <linearGradient
        id='banner_svg__SVGID_40_'
        gradientUnits='userSpaceOnUse'
        x1={213.59}
        y1={15.355}
        x2={488.736}
        y2={4.37}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#00349b' />
        <stop offset={0.5} stopColor='#005cff' />
        <stop offset={1} stopColor='#007aff' />
      </linearGradient>
      <path
        d='M471.06 297.06l-61.74-35.48a3.19 3.19 0 00-3.19 0l-60.53 34.95c-.36.21-.59.58-.62.99v.1c-.01.45.23.87.63 1.09l61.73 35.5c.99.57 2.2.57 3.19 0l60.54-34.95c.38-.22.61-.61.62-1.05.03-.47-.21-.91-.63-1.15z'
        opacity={0.2}
        fill='url(#banner_svg__SVGID_40_)'
      />
      <linearGradient
        id='banner_svg__SVGID_41_'
        gradientUnits='userSpaceOnUse'
        x1={356.804}
        y1={8.486}
        x2={459.855}
        y2={8.486}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#00bfa5' />
        <stop offset={0.5} stopColor='#6dd9ca' />
        <stop offset={1} stopColor='#b5f2ea' />
      </linearGradient>
      <path
        d='M408.81 328.89c-.54 0-1.07-.14-1.54-.41l-49.71-28.58c-.47-.27-.76-.77-.76-1.32v-.15c.05-.49.32-.92.75-1.16l48.74-28.14a3.13 3.13 0 013.08 0l49.71 28.59c.5.28.79.81.77 1.38a1.5 1.5 0 01-.77 1.27l-48.7 28.13c-.47.26-1.02.4-1.57.39zm-.96-59.2c-.36 0-.71.1-1.03.28l-48.7 28.17c-.14.08-.23.23-.25.4-.02.19.08.38.25.47l49.71 28.59c.63.37 1.42.37 2.05 0l48.7-28.16a.46.46 0 00.25-.41.475.475 0 00-.24-.46l-49.71-28.59a1.98 1.98 0 00-1.03-.29z'
        fill='url(#banner_svg__SVGID_41_)'
      />
      <linearGradient
        id='banner_svg__SVGID_42_'
        gradientUnits='userSpaceOnUse'
        x1={248.834}
        y1={-3.586}
        x2={545.328}
        y2={-18.167}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M471.67 253.88v17.29c-.03.42-.25.81-.62 1.03l-60.54 34.95c-.98.57-2.21.57-3.19 0l-61.73-35.51c-.41-.23-.65-.67-.62-1.14v-17.22c-.01.45.23.87.63 1.09l61.73 35.5c.99.57 2.2.57 3.19 0l60.54-34.95c.37-.21.61-.61.61-1.04z'
        fill='url(#banner_svg__SVGID_42_)'
      />
      <linearGradient
        id='banner_svg__SVGID_43_'
        gradientUnits='userSpaceOnUse'
        x1={213.589}
        y1={-28.991}
        x2={488.735}
        y2={-39.977}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M471.06 252.73l-61.74-35.5a3.19 3.19 0 00-3.19 0l-60.53 34.95c-.36.21-.59.58-.62.99v.1c-.01.45.23.87.63 1.09l61.73 35.5c.99.57 2.2.57 3.19 0l60.54-34.95c.38-.22.61-.61.62-1.05.02-.46-.22-.89-.63-1.13z'
        fill='url(#banner_svg__SVGID_43_)'
      />
      <linearGradient
        id='banner_svg__SVGID_44_'
        gradientUnits='userSpaceOnUse'
        x1={478.984}
        y1={-29.657}
        x2={218.519}
        y2={-28.387}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#00349b' />
        <stop offset={0.5} stopColor='#005cff' />
        <stop offset={1} stopColor='#007aff' />
      </linearGradient>
      <path
        d='M467.93 236.16v16.28c-.02.4-.24.77-.59.96l-56.95 32.87c-.93.53-2.07.53-3 0l-58.06-33.34c-.39-.22-.61-.63-.59-1.07v-16.22c0 .42.23.82.59 1.03L407.39 270c.93.54 2.07.54 3 0l56.94-32.87c.35-.2.58-.58.59-.98l.01.01z'
        fill='url(#banner_svg__SVGID_44_)'
      />
      <linearGradient
        id='banner_svg__SVGID_45_'
        gradientUnits='userSpaceOnUse'
        x1={248.834}
        y1={-28.627}
        x2={545.328}
        y2={-43.208}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M471.67 228.82v17.3c-.03.42-.25.81-.62 1.03L410.5 282.1c-.98.58-2.21.58-3.19 0l-61.73-35.5c-.41-.23-.65-.67-.62-1.14v-17.23c-.01.45.23.87.63 1.09l61.73 35.5c.99.57 2.2.57 3.19 0l60.54-34.95c.38-.21.62-.61.62-1.05z'
        fill='url(#banner_svg__SVGID_45_)'
      />
      <linearGradient
        id='banner_svg__SVGID_46_'
        gradientUnits='userSpaceOnUse'
        x1={213.589}
        y1={-54.035}
        x2={488.735}
        y2={-65.02}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M471.06 227.69l-61.74-35.5a3.19 3.19 0 00-3.19 0l-60.53 34.95c-.36.21-.59.58-.62.99v.1c-.01.45.23.87.63 1.09l61.73 35.5c.99.57 2.2.57 3.19 0l60.54-34.95c.6-.36.8-1.14.45-1.74-.1-.19-.26-.34-.46-.44z'
        fill='url(#banner_svg__SVGID_46_)'
      />
      <linearGradient
        id='banner_svg__SVGID_47_'
        gradientUnits='userSpaceOnUse'
        x1={478.858}
        y1={-54.769}
        x2={218.393}
        y2={-53.498}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#00349b' />
        <stop offset={0.5} stopColor='#005cff' />
        <stop offset={1} stopColor='#007aff' />
      </linearGradient>
      <path
        d='M467.93 211.04v16.29c-.02.4-.23.77-.59.96l-56.95 32.85c-.93.54-2.07.54-3 0l-58.06-33.34c-.39-.22-.61-.63-.59-1.07v-16.22c0 .42.23.82.59 1.03l58.06 33.34c.93.54 2.07.54 3 0l56.95-32.87c.34-.2.57-.57.59-.97z'
        fill='url(#banner_svg__SVGID_47_)'
      />
      <linearGradient
        id='banner_svg__SVGID_48_'
        gradientUnits='userSpaceOnUse'
        x1={248.835}
        y1={-53.737}
        x2={545.329}
        y2={-68.317}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M471.67 203.71v17.32c-.02.42-.25.81-.62 1.03L410.5 257c-.98.57-2.21.57-3.19 0l-61.73-35.5c-.41-.23-.66-.68-.62-1.14v-17.24c0 .45.23.87.63 1.08l61.73 35.5c.98.58 2.21.58 3.19 0l60.54-34.95c.38-.21.62-.61.62-1.04z'
        fill='url(#banner_svg__SVGID_48_)'
      />
      <linearGradient
        id='banner_svg__SVGID_49_'
        gradientUnits='userSpaceOnUse'
        x1={213.589}
        y1={-79.144}
        x2={488.735}
        y2={-90.138}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M471.06 202.57l-61.74-35.5c-.98-.58-2.21-.58-3.19 0l-60.53 34.95c-.36.21-.59.58-.62.99v.11c0 .45.23.87.63 1.08l61.73 35.5c.98.58 2.21.58 3.19 0l60.54-34.95c.6-.36.8-1.14.44-1.74-.1-.18-.26-.33-.45-.44z'
        fill='url(#banner_svg__SVGID_49_)'
      />
      <linearGradient
        id='banner_svg__SVGID_50_'
        gradientUnits='userSpaceOnUse'
        x1={362.523}
        y1={-56.547}
        x2={401.904}
        y2={-56.547}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path
        d='M401.43 245.29c-.08 0-.17-.02-.24-.06l-38.42-22.1a.468.468 0 01-.19-.64l.01-.01c.14-.23.42-.3.65-.17l38.43 22.09c.23.13.31.42.18.65-.1.14-.25.24-.42.24z'
        fill='url(#banner_svg__SVGID_50_)'
      />
      <linearGradient
        id='banner_svg__SVGID_51_'
        gradientUnits='userSpaceOnUse'
        x1={362.492}
        y1={-31.187}
        x2={401.895}
        y2={-31.187}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path
        d='M401.43 270.66c-.08 0-.17-.02-.24-.06l-38.42-22.09a.475.475 0 01-.23-.63c.11-.23.4-.34.63-.23.02.01.05.03.06.04l38.43 22.09c.23.13.31.42.18.65-.08.14-.24.23-.41.23z'
        fill='url(#banner_svg__SVGID_51_)'
      />
      <linearGradient
        id='banner_svg__SVGID_52_'
        gradientUnits='userSpaceOnUse'
        x1={362.523}
        y1={-7.305}
        x2={401.904}
        y2={-7.305}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path
        d='M401.43 294.51c-.08 0-.17-.03-.24-.06l-38.42-22.05a.468.468 0 01-.19-.64l.01-.01c.13-.23.42-.31.65-.18l38.43 22.1c.23.13.31.42.18.65-.1.12-.26.2-.42.19z'
        fill='url(#banner_svg__SVGID_52_)'
      />
    </g>
    <g>
      <linearGradient
        id='banner_svg__SVGID_53_'
        gradientUnits='userSpaceOnUse'
        x1={68.953}
        y1={45.185}
        x2={220.224}
        y2={8.598}
        gradientTransform='matrix(1 .00401 -.00401 1 -.057 288.916)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M223.1 309.77a.675.675 0 00-.33-.59l-41.72-24.28c-.52-.3-1.15-.3-1.68 0l-31.83 18.2c-.2.11-.32.32-.32.55v.06c.01.23.14.42.32.53l41.72 24.28c.51.3 1.16.3 1.68 0l31.82-18.19c.19-.12.32-.32.33-.53l.01-.03z'
        fill='url(#banner_svg__SVGID_53_)'
      />
      <linearGradient
        id='banner_svg__SVGID_54_'
        gradientUnits='userSpaceOnUse'
        x1={155.71}
        y1={23.378}
        x2={214.602}
        y2={9.394}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#00349b' />
        <stop offset={0.5} stopColor='#005cff' />
        <stop offset={1} stopColor='#007aff' />
      </linearGradient>
      <path
        d='M189.28 325.16c-.27 0-.53-.06-.77-.2l-34.7-20.2a.666.666 0 01-.33-.55v-.06c0-.23.13-.45.33-.57l26.48-15.14c.47-.27 1.05-.27 1.51 0l34.71 20.2c.17.1.3.28.32.48v.13a.71.71 0 01-.34.57l-26.47 15.14c-.22.13-.48.2-.74.2zm-8.25-36.66c-.22 0-.43.05-.62.17l-26.48 15.14c-.13.07-.21.2-.21.34v.05c.01.14.09.26.21.33l34.7 20.2c.4.23.87.23 1.27 0l26.47-15.14c.11-.06.19-.18.21-.31v-.05c.01-.15-.07-.3-.21-.38l-34.7-20.19c-.2-.1-.41-.16-.64-.16z'
        fill='url(#banner_svg__SVGID_54_)'
      />
      <linearGradient
        id='banner_svg__SVGID_55_'
        gradientUnits='userSpaceOnUse'
        x1={37.727}
        y1={49.772}
        x2={253.567}
        y2={18.528}
        gradientTransform='matrix(1 .00401 -.00401 1 -.057 288.916)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M223.1 309.79v8.25a.64.64 0 01-.33.58l-31.82 18.2c-.51.3-1.16.3-1.68 0l-41.72-24.28a.65.65 0 01-.32-.58v-8.25c.01.23.14.42.32.53l41.72 24.28c.51.3 1.16.3 1.68 0l31.82-18.2c.18-.11.31-.31.33-.53z'
        fill='url(#banner_svg__SVGID_55_)'
      />
      <linearGradient
        id='banner_svg__SVGID_56_'
        gradientUnits='userSpaceOnUse'
        x1={179.706}
        y1={38.221}
        x2={184.806}
        y2={38.221}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#d99100' />
        <stop offset={0.5} stopColor='#fac800' />
        <stop offset={1} stopColor='#fbe20e' />
      </linearGradient>
      <path
        d='M184.19 330.26l-3.87-2.22c-.35-.21-.58-.56-.62-.96 0-.34.28-.46.62-.26l3.87 2.21c.34.21.57.57.61.97.01.33-.27.45-.61.26z'
        fill='url(#banner_svg__SVGID_56_)'
      />
      <linearGradient
        id='banner_svg__SVGID_57_'
        gradientUnits='userSpaceOnUse'
        x1={180.513}
        y1={-85.57}
        x2={181.189}
        y2={8.539}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#00bfa5' />
        <stop offset={0.5} stopColor='#6dd9ca' />
        <stop offset={1} stopColor='#b5f2ea' />
      </linearGradient>
      <path
        d='M180.89 304.2c-.28 0-.51-.23-.51-.51v-94.2c-.04-.28.16-.54.44-.58a.517.517 0 01.58.58v94.2c-.01.27-.23.5-.51.51z'
        fill='url(#banner_svg__SVGID_57_)'
      />
      <linearGradient
        id='banner_svg__SVGID_58_'
        gradientUnits='userSpaceOnUse'
        x1={184.379}
        y1={-83.317}
        x2={185.055}
        y2={10.792}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#00bfa5' />
        <stop offset={0.5} stopColor='#6dd9ca' />
        <stop offset={1} stopColor='#b5f2ea' />
      </linearGradient>
      <path
        d='M184.74 306.45a.51.51 0 01-.5-.51v-94.2c-.04-.28.16-.54.44-.58a.517.517 0 01.58.58v94.2c0 .28-.24.51-.52.51z'
        fill='url(#banner_svg__SVGID_58_)'
      />
      <linearGradient
        id='banner_svg__SVGID_59_'
        gradientUnits='userSpaceOnUse'
        x1={188.385}
        y1={-80.983}
        x2={189.07}
        y2={13.126}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#00bfa5' />
        <stop offset={0.5} stopColor='#6dd9ca' />
        <stop offset={1} stopColor='#b5f2ea' />
      </linearGradient>
      <path
        d='M188.76 308.79c-.28 0-.51-.23-.51-.51v-94.2c0-.28.23-.51.51-.51.28.01.5.23.51.51v94.2c0 .27-.23.5-.51.51z'
        fill='url(#banner_svg__SVGID_59_)'
      />
      <g>
        <linearGradient
          id='banner_svg__SVGID_60_'
          gradientUnits='userSpaceOnUse'
          x1={196.434}
          y1={-72.268}
          x2={153.241}
          y2={-139.522}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#007aff' />
          <stop offset={0.5} stopColor='#009cff' />
          <stop offset={1} stopColor='#d9eaff' />
        </linearGradient>
        <path
          d='M256.2 246.16c0 4.42-1.58 7.57-4.22 9.17l-20.82 11.87c2.39-1.69 3.82-4.73 3.82-8.91 0-10.06-8.16-22.93-18.2-28.84-.41-.23-.8-.43-1.21-.63-3.46-13.67-14.25-28.47-27.04-35.92a37.013 37.013 0 00-7.13-3.25c-4.93-1.59-9.32-1.56-12.81-.11a12.24 12.24 0 00-4.43 3.17c-4.91-2.25-9.3-2.54-12.62-1.1a9.41 9.41 0 00-5.1 5.52c-.34-.2-.65-.46-.96-.66-4.15-2.41-7.89-2.79-10.44-1.38l21.12-12.07c2.35-1.36 5.74-1.19 9.58.78.31.16.63.34.95.53.32.19.66.45.97.67.46-1.26 1.17-2.42 2.08-3.4l.09-.08.12-.13c3.23-3.23 8.53-3.75 14.71-1.12.23.09.46.2.69.31.72-.81 1.55-1.53 2.46-2.13.19-.13.38-.24.58-.35l.17-.11c1.86-1 3.92-1.57 6.03-1.66 4.38-.24 9.58 1.16 15.15 4.42 12.79 7.45 23.57 22.23 27.04 35.92.41.19.77.39 1.18.62 10.1 5.94 18.24 18.82 18.24 28.87z'
          fill='url(#banner_svg__SVGID_60_)'
        />
        <linearGradient
          id='banner_svg__SVGID_61_'
          gradientUnits='userSpaceOnUse'
          x1={179.535}
          y1={-82.651}
          x2={169.974}
          y2={-113.985}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#007aff' />
          <stop offset={0.5} stopColor='#009cff' />
          <stop offset={1} stopColor='#d9eaff' />
        </linearGradient>
        <path
          d='M194.6 176.39c-2.11.09-4.17.66-6.03 1.66l-.17.11c-.2.11-.39.23-.58.35-.91.59-1.74 1.32-2.46 2.13-.23-.11-.46-.22-.69-.31-5.33-2.27-10.02-2.18-13.27 0l-19.83 11.38-1.3.7s17.86 7.3 18.29 6.86c.23-.22 6.5-4.87 12.86-9.54l13.23-9.73c.06-1.2.04-2.41-.05-3.61z'
          fill='url(#banner_svg__SVGID_61_)'
        />
        <linearGradient
          id='banner_svg__SVGID_62_'
          gradientUnits='userSpaceOnUse'
          x1={255.28}
          y1={4.951}
          x2={179.924}
          y2={-128.152}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#007aff' />
          <stop offset={0.5} stopColor='#009cff' />
          <stop offset={1} stopColor='#d9eaff' />
        </linearGradient>
        <path
          d='M236.81 216.73l-21.24 12.12-22.96-25.57-24.93-13.18.9-.51 16.07-9.24 3.15-1.8.25-.14.32-.21.17-.11c5.18-2.86 12.74-2.17 21.18 2.76 12.82 7.41 23.6 22.16 27.09 35.88z'
          fill='url(#banner_svg__SVGID_62_)'
        />
        <linearGradient
          id='banner_svg__SVGID_63_'
          gradientUnits='userSpaceOnUse'
          x1={-33.962}
          y1={-61.685}
          x2={321.829}
          y2={-61.685}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#007aff' />
          <stop offset={0.5} stopColor='#009cff' />
          <stop offset={1} stopColor='#d9eaff' />
        </linearGradient>
        <path
          d='M216.78 229.48c-.41-.23-.8-.43-1.21-.63-3.47-13.68-14.25-28.47-27.04-35.92-10.44-6.07-19.49-5.7-24.39-.17-8.37-3.84-15.22-1.98-17.68 4.42-.35-.2-.67-.46-.98-.66-4.15-2.42-7.88-2.78-10.44-1.38-2.17 1.17-3.5 3.6-3.5 7.16 0 7.69 6.25 17.55 13.93 22.02l71.3 41.57c5.19 3.04 9.91 3.6 13.21 2.01.41-.21.8-.43 1.18-.69 2.38-1.69 3.81-4.73 3.81-8.91.01-10.07-8.16-22.94-18.19-28.82z'
          fill='url(#banner_svg__SVGID_63_)'
        />
        <linearGradient
          id='banner_svg__SVGID_64_'
          gradientUnits='userSpaceOnUse'
          x1={250.353}
          y1={-7.026}
          x2={225.111}
          y2={-89.789}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#007aff' />
          <stop offset={0.5} stopColor='#009cff' />
          <stop offset={1} stopColor='#d9eaff' />
        </linearGradient>
        <path
          d='M256.2 246.16c0 4.42-1.58 7.57-4.22 9.17l-20.82 11.87c2.39-1.69 3.82-4.73 3.82-8.91 0-10.06-8.16-22.93-18.2-28.84-.41-.23-.8-.43-1.21-.63l21.24-12.12c.41.19.77.39 1.18.62 10.07 5.91 18.21 18.79 18.21 28.84z'
          fill='url(#banner_svg__SVGID_64_)'
        />
      </g>
    </g>
    <g>
      <linearGradient
        id='banner_svg__SVGID_65_'
        gradientUnits='userSpaceOnUse'
        x1={303.471}
        y1={26.261}
        x2={265.604}
        y2={25.008}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path
        d='M296.79 311.88l-11.99-6.94a.742.742 0 00-.68 0l-10.21 5.91c-.07.04-.12.11-.12.19v8.8c.01.1.08.19.17.23l11.99 6.94c.22.11.46.11.68 0l10.21-5.96c.06-.03.11-.08.12-.15v-8.84a.302.302 0 00-.17-.18z'
        fill='url(#banner_svg__SVGID_65_)'
      />
      <linearGradient
        id='banner_svg__SVGID_66_'
        gradientUnits='userSpaceOnUse'
        x1={297.219}
        y1={22.793}
        x2={273.754}
        y2={22.009}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#e7f0ff' />
        <stop offset={1} stopColor='#fff' />
      </linearGradient>
      <path
        d='M296.96 312.1v8.83a.314.314 0 00-.17-.23l-11.99-6.95a.742.742 0 00-.68 0l-10.21 5.91c-.06.04-.11.09-.12.16v-8.8c0-.08.05-.15.12-.19l10.21-5.91c.22-.11.46-.11.68 0l11.99 6.94c.09.05.16.13.17.24z'
        opacity={0.2}
        fill='url(#banner_svg__SVGID_66_)'
      />
      <linearGradient
        id='banner_svg__SVGID_67_'
        gradientUnits='userSpaceOnUse'
        x1={296.962}
        y1={21.634}
        x2={273.792}
        y2={20.868}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#e7f0ff' />
        <stop offset={1} stopColor='#fff' />
      </linearGradient>
      <path
        d='M296.79 311.88l-11.99-6.94a.742.742 0 00-.68 0l-10.21 5.91c-.07.04-.12.11-.12.19a.3.3 0 00.17.21l11.99 6.95c.22.11.46.11.68 0l10.21-5.92c.07-.03.12-.1.12-.17-.02-.11-.08-.2-.17-.23z'
        opacity={0.2}
        fill='url(#banner_svg__SVGID_67_)'
      />
    </g>
    <g>
      <linearGradient
        id='banner_svg__SVGID_68_'
        gradientUnits='userSpaceOnUse'
        x1={486.013}
        y1={-128.182}
        x2={448.155}
        y2={-129.435}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path
        d='M479.34 157.44l-11.99-6.95a.742.742 0 00-.68 0l-10.21 5.91c-.07.03-.13.1-.12.18v8.81c.01.1.08.19.17.23l11.98 6.95c.22.11.47.11.68 0l10.21-5.91c.06-.04.11-.09.12-.16v-8.83a.28.28 0 00-.16-.23z'
        fill='url(#banner_svg__SVGID_68_)'
      />
      <linearGradient
        id='banner_svg__SVGID_69_'
        gradientUnits='userSpaceOnUse'
        x1={479.766}
        y1={-131.643}
        x2={456.303}
        y2={-132.419}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#e7f0ff' />
        <stop offset={1} stopColor='#fff' />
      </linearGradient>
      <path
        d='M479.51 157.67v8.82a.333.333 0 00-.17-.23l-11.99-6.94a.742.742 0 00-.68 0l-10.21 5.92c-.07.03-.12.09-.12.16v-8.81c-.01-.08.05-.15.12-.18l10.21-5.91c.22-.11.46-.11.68 0l11.99 6.95c.09.04.15.12.17.22z'
        opacity={0.2}
        fill='url(#banner_svg__SVGID_69_)'
      />
      <linearGradient
        id='banner_svg__SVGID_70_'
        gradientUnits='userSpaceOnUse'
        x1={479.513}
        y1={-132.813}
        x2={456.341}
        y2={-133.579}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#e7f0ff' />
        <stop offset={1} stopColor='#fff' />
      </linearGradient>
      <path
        d='M479.34 157.44l-11.99-6.95a.742.742 0 00-.68 0l-10.21 5.91c-.07.03-.13.1-.12.18.02.1.08.18.17.22l11.98 6.94c.22.11.47.11.68 0l10.21-5.91c.06-.04.11-.1.12-.17a.265.265 0 00-.16-.22z'
        opacity={0.2}
        fill='url(#banner_svg__SVGID_70_)'
      />
    </g>
    <g>
      <linearGradient
        id='banner_svg__SVGID_71_'
        gradientUnits='userSpaceOnUse'
        x1={91.299}
        y1={-68.104}
        x2={67.228}
        y2={-68.897}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path
        d='M87.06 219.23l-7.62-4.42a.466.466 0 00-.43 0l-6.49 3.76a.13.13 0 00-.07.12v5.6c.01.06.05.13.11.15l7.61 4.42c.14.07.3.07.43 0l6.5-3.76c.04-.03.06-.06.07-.11v-5.61a.197.197 0 00-.11-.15z'
        fill='url(#banner_svg__SVGID_71_)'
      />
      <linearGradient
        id='banner_svg__SVGID_72_'
        gradientUnits='userSpaceOnUse'
        x1={87.33}
        y1={-70.342}
        x2={72.412}
        y2={-70.838}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#e7f0ff' />
        <stop offset={1} stopColor='#fff' />
      </linearGradient>
      <path
        d='M87.16 219.34v5.61c-.01-.06-.05-.12-.11-.14l-7.62-4.42a.466.466 0 00-.43 0l-6.49 3.76c-.04.02-.06.05-.07.1v-5.6c0-.05.03-.09.07-.12l6.49-3.76c.14-.07.3-.07.43 0l7.62 4.42c.08.04.11.09.11.15z'
        opacity={0.2}
        fill='url(#banner_svg__SVGID_72_)'
      />
      <linearGradient
        id='banner_svg__SVGID_73_'
        gradientUnits='userSpaceOnUse'
        x1={87.168}
        y1={-71.047}
        x2={72.436}
        y2={-71.534}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#e7f0ff' />
        <stop offset={1} stopColor='#fff' />
      </linearGradient>
      <path
        d='M87.06 219.23l-7.62-4.42a.466.466 0 00-.43 0l-6.49 3.76a.13.13 0 00-.07.12c.02.05.05.11.11.14l7.61 4.42c.14.07.3.07.43 0l6.5-3.76c.05-.02.07-.06.07-.11-.01-.1-.04-.15-.11-.15z'
        opacity={0.2}
        fill='url(#banner_svg__SVGID_73_)'
      />
    </g>
    <g>
      <linearGradient
        id='banner_svg__SVGID_74_'
        gradientUnits='userSpaceOnUse'
        x1={87.063}
        y1={-178.138}
        x2={62.984}
        y2={-178.931}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path
        d='M82.81 109.15l-7.61-4.37a.466.466 0 00-.43 0l-6.5 3.76a.13.13 0 00-.07.12v5.61c.01.06.05.12.11.14l7.62 4.42c.14.07.3.07.43 0l6.49-3.76c.04-.02.06-.05.07-.1v-5.68c-.01-.07-.05-.11-.11-.14z'
        fill='url(#banner_svg__SVGID_74_)'
      />
      <linearGradient
        id='banner_svg__SVGID_75_'
        gradientUnits='userSpaceOnUse'
        x1={83.082}
        y1={-180.396}
        x2={68.168}
        y2={-180.882}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#e7f0ff' />
        <stop offset={1} stopColor='#fff' />
      </linearGradient>
      <path
        d='M82.92 109.28v5.61c0-.05 0-.1-.11-.14l-7.61-4.42a.466.466 0 00-.43 0l-6.5 3.76c-.04.02-.06.05-.07.1v-5.6c0-.05.03-.09.07-.12l6.5-3.76c.14-.07.3-.07.43 0l7.61 4.42c.06.04.1.09.11.15z'
        opacity={0.2}
        fill='url(#banner_svg__SVGID_75_)'
      />
      <linearGradient
        id='banner_svg__SVGID_76_'
        gradientUnits='userSpaceOnUse'
        x1={82.924}
        y1={-181.08}
        x2={68.192}
        y2={-181.567}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#e7f0ff' />
        <stop offset={1} stopColor='#fff' />
      </linearGradient>
      <path
        d='M82.81 109.15l-7.61-4.37a.466.466 0 00-.43 0l-6.5 3.76a.13.13 0 00-.07.12c.02.05.05.11.11.14l7.62 4.42c.14.07.3.07.43 0l6.49-3.76c.05-.02.07-.06.07-.11.01-.09-.04-.16-.11-.2z'
        opacity={0.2}
        fill='url(#banner_svg__SVGID_76_)'
      />
    </g>
    <g>
      <linearGradient
        id='banner_svg__SVGID_77_'
        gradientUnits='userSpaceOnUse'
        x1={281.177}
        y1={-107.477}
        x2={257.107}
        y2={-108.27}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path
        d='M276.93 179.86l-7.61-4.42a.466.466 0 00-.43 0l-6.5 3.76c-.05.02-.07.06-.07.12v5.6c.01.06.05.13.11.15l7.62 4.42c.14.07.3.07.43 0l6.49-3.77c.04-.02.06-.05.07-.1V180c-.01-.06-.04-.12-.11-.14z'
        fill='url(#banner_svg__SVGID_77_)'
      />
      <linearGradient
        id='banner_svg__SVGID_78_'
        gradientUnits='userSpaceOnUse'
        x1={277.203}
        y1={-109.677}
        x2={262.285}
        y2={-110.173}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#e7f0ff' />
        <stop offset={1} stopColor='#fff' />
      </linearGradient>
      <path
        d='M277.01 180v5.61c-.01-.06-.05-.12-.11-.14l-7.61-4.42a.466.466 0 00-.43 0l-6.5 3.77c-.04.02-.06.05-.07.1v-5.6c0-.05.03-.1.07-.12l6.5-3.76c.14-.07.3-.07.43 0l7.61 4.42c.06.02.11.08.11.14z'
        opacity={0.2}
        fill='url(#banner_svg__SVGID_78_)'
      />
      <linearGradient
        id='banner_svg__SVGID_79_'
        gradientUnits='userSpaceOnUse'
        x1={277.045}
        y1={-110.419}
        x2={262.313}
        y2={-110.906}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#e7f0ff' />
        <stop offset={1} stopColor='#fff' />
      </linearGradient>
      <path
        d='M276.93 179.86l-7.61-4.42a.466.466 0 00-.43 0l-6.5 3.76c-.05.02-.07.06-.07.12.02.05.05.11.11.14l7.62 4.42c.14.07.3.07.43 0l6.49-3.76c.05-.02.07-.06.07-.11-.01-.07-.04-.13-.11-.15z'
        opacity={0.2}
        fill='url(#banner_svg__SVGID_79_)'
      />
    </g>
    <g>
      <linearGradient
        id='banner_svg__SVGID_80_'
        gradientUnits='userSpaceOnUse'
        x1={283.194}
        y1={33.44}
        x2={262.008}
        y2={32.737}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path
        d='M279.46 321.12l-6.7-3.88a.5.5 0 00-.38 0l-5.71 3.32c-.04.02-.06.05-.06.1v4.93c0 .05 0 .09.1.13l6.7 3.88c.12.06.26.06.38 0l5.71-3.31s.06-.05.06-.09v-4.95a.195.195 0 00-.1-.13z'
        fill='url(#banner_svg__SVGID_80_)'
      />
      <linearGradient
        id='banner_svg__SVGID_81_'
        gradientUnits='userSpaceOnUse'
        x1={279.7}
        y1={31.497}
        x2={266.573}
        y2={31.065}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#e7f0ff' />
        <stop offset={1} stopColor='#fff' />
      </linearGradient>
      <path
        d='M279.56 321.25v4.94a.172.172 0 00-.1-.12l-6.7-3.89a.5.5 0 00-.38 0l-5.71 3.32c-.04.02-.06.05-.06.09v-4.93c0-.05.03-.08.06-.1l5.71-3.32a.5.5 0 01.38 0l6.7 3.88c.06.04.1.08.1.13z'
        opacity={0.2}
        fill='url(#banner_svg__SVGID_81_)'
      />
      <linearGradient
        id='banner_svg__SVGID_82_'
        gradientUnits='userSpaceOnUse'
        x1={279.558}
        y1={30.848}
        x2={266.594}
        y2={30.416}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#e7f0ff' />
        <stop offset={1} stopColor='#fff' />
      </linearGradient>
      <path
        d='M279.46 321.12l-6.7-3.88a.5.5 0 00-.38 0l-5.71 3.32c-.04.02-.06.05-.06.1.02.05.05.09.1.12l6.7 3.88a.5.5 0 00.38 0l5.71-3.31c.04-.02.06-.05.06-.1 0 0-.04-.09-.1-.13z'
        opacity={0.2}
        fill='url(#banner_svg__SVGID_82_)'
      />
    </g>
    <g>
      <linearGradient
        id='banner_svg__SVGID_83_'
        gradientUnits='userSpaceOnUse'
        x1={82.801}
        y1={32.947}
        x2={131.167}
        y2={25.098}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M104.01 317.09l-3.36-1.94a.426.426 0 00-.41 0l-4.45 2.57-6.11.46v3.15c0 .05.03.11.08.14l7.96 4.6c.13.07.29.07.41 0l10.48-6.05c.05-.03.07-.07.08-.13v-3.16l-4.68.36z'
        fill='url(#banner_svg__SVGID_83_)'
      />
      <linearGradient
        id='banner_svg__SVGID_84_'
        gradientUnits='userSpaceOnUse'
        x1={74.619}
        y1={33.404}
        x2={113.253}
        y2={23.536}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M89.67 318.18c0 .05.04.11.08.14l7.96 4.59c.13.07.29.07.41 0l10.48-6.04c.05-.03.07-.08.08-.14 0-.05-.03-.11-.08-.14l-7.96-4.6a.426.426 0 00-.41 0l-10.48 6.08a.16.16 0 00-.08.14v-.03z'
        fill='url(#banner_svg__SVGID_84_)'
      />
      <path
        className='banner_svg__st86'
        d='M93.78 317.62c-.01.03-.01.05 0 .07l4.51 2.58c.07.05.16.05.23 0l5.88-3.39c.03-.02.05-.05.05-.07 0-.04-.02-.06-.05-.08l-4.46-2.58a.195.195 0 00-.23 0l-5.88 3.39a.11.11 0 000 .08h-.05z'
      />
      <linearGradient
        id='banner_svg__SVGID_85_'
        gradientUnits='userSpaceOnUse'
        x1={99.22}
        y1={-26.275}
        x2={98.626}
        y2={167.477}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#007aff' stopOpacity={0} />
        <stop offset={0.5} stopColor='#009cff' stopOpacity={0.5} />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M104.4 254.72l-4.46-2.58a.235.235 0 00-.23 0l-5.88 3.4c-.02.03-.02.05 0 .08 0 .34-.27 61.84 0 61.99-.01.03-.01.05 0 .07l4.51 2.58c.07.05.16.05.23 0l5.88-3.39c.03-.02.05-.05.05-.07v-62c-.02-.05-.05-.08-.1-.08z'
        fill='url(#banner_svg__SVGID_85_)'
      />
      <linearGradient
        id='banner_svg__SVGID_86_'
        gradientUnits='userSpaceOnUse'
        x1={99.366}
        y1={-27.978}
        x2={98.276}
        y2={72.286}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#007aff' stopOpacity={0} />
        <stop offset={0.5} stopColor='#009cff' stopOpacity={0.5} />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M101.51 259.5l-2.05-1.19a.108.108 0 00-.11 0l-2.7 1.56v57.49l2.05 1.19c.04.02.08.02.12 0l2.7-1.57-.01-57.48z'
        opacity={0.5}
        fill='url(#banner_svg__SVGID_86_)'
      />
    </g>
    <g>
      <linearGradient
        id='banner_svg__SVGID_87_'
        gradientUnits='userSpaceOnUse'
        x1={306.221}
        y1={-40.613}
        x2={354.587}
        y2={-48.462}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M327.45 243.52l-3.36-1.95a.426.426 0 00-.41 0l-4.48 2.6-6.1.46v3.15c0 .05.04.1.08.13l7.96 4.6c.13.07.29.07.41 0l10.48-6.04c.05-.03.07-.08.08-.14v-3.16l-4.66.35z'
        fill='url(#banner_svg__SVGID_87_)'
      />
      <linearGradient
        id='banner_svg__SVGID_88_'
        gradientUnits='userSpaceOnUse'
        x1={298.037}
        y1={-40.173}
        x2={336.679}
        y2={-50.032}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M313.09 244.6c0 .05.04.11.08.14l7.96 4.59c.13.07.29.07.41 0l10.48-6.04a.16.16 0 00.08-.14c0-.05-.04-.11-.08-.14l-7.96-4.59a.38.38 0 00-.41 0l-10.48 6.04c-.05.04-.08.09-.08.14z'
        fill='url(#banner_svg__SVGID_88_)'
      />
      <path
        className='banner_svg__st86'
        d='M317.2 244.05c0 .03.02.05.05.07l4.46 2.58c.07.05.17.05.24 0l5.88-3.39c.01-.03.01-.05 0-.07a.11.11 0 000-.08l-4.51-2.58a.235.235 0 00-.23 0l-5.88 3.39c-.04.02-.05.05-.05.09l.04-.01z'
      />
      <linearGradient
        id='banner_svg__SVGID_89_'
        gradientUnits='userSpaceOnUse'
        x1={322.576}
        y1={-99.847}
        x2={321.981}
        y2={93.905}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#007aff' stopOpacity={0} />
        <stop offset={0.5} stopColor='#009cff' stopOpacity={0.5} />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M327.82 181.15l-4.51-2.58a.195.195 0 00-.23 0l-5.84 3.42c-.03.02-.05.05-.05.08 0 .34-.27 61.84 0 61.99 0 .03.02.05.05.07l4.46 2.58c.07.05.17.05.24 0l5.88-3.39c.01-.03.01-.05 0-.07v-62a.09.09 0 000-.1z'
        fill='url(#banner_svg__SVGID_89_)'
      />
      <linearGradient
        id='banner_svg__SVGID_90_'
        gradientUnits='userSpaceOnUse'
        x1={322.76}
        y1={-101.55}
        x2={321.66}
        y2={-1.286}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#007aff' stopOpacity={0} />
        <stop offset={0.5} stopColor='#009cff' stopOpacity={0.5} />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M324.93 185.96l-2.08-1.26a.108.108 0 00-.11 0l-2.7 1.56v57.49l2.06 1.19c.04.02.07.02.11 0l2.7-1.56.02-57.42z'
        opacity={0.5}
        fill='url(#banner_svg__SVGID_90_)'
      />
    </g>
    <g>
      <linearGradient
        id='banner_svg__SVGID_91_'
        gradientUnits='userSpaceOnUse'
        x1={18.864}
        y1={-122.887}
        x2={67.229}
        y2={-130.736}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M40.07 161.27l-3.36-1.95a.446.446 0 00-.42 0l-4.42 2.55-6.11.46v3.15c0 .05.04.1.08.13l7.95 4.6c.14.07.29.07.42 0l10.48-6.04c.05-.04.07-.08.08-.14v-3.16l-4.7.4z'
        fill='url(#banner_svg__SVGID_91_)'
      />
      <linearGradient
        id='banner_svg__SVGID_92_'
        gradientUnits='userSpaceOnUse'
        x1={10.68}
        y1={-122.442}
        x2={49.313}
        y2={-132.301}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M25.73 162.34c0 .05.03.11.08.14l7.95 4.59c.13.08.3.08.42 0l10.48-6.04c.05-.03.07-.08.08-.14a.248.248 0 00-.08-.14l-7.96-4.59a.397.397 0 00-.42 0l-10.47 6.02a.16.16 0 00-.08.14v.02z'
        fill='url(#banner_svg__SVGID_92_)'
      />
      <path
        className='banner_svg__st86'
        d='M29.84 161.79c-.01.03-.01.05 0 .07l4.51 2.58c.07.05.16.05.23 0l5.88-3.39c.03-.02.05-.05.05-.07 0-.04-.02-.06-.05-.08l-4.51-2.58a.235.235 0 00-.23 0l-5.88 3.4c-.01.02-.01.05 0 .07z'
      />
      <linearGradient
        id='banner_svg__SVGID_93_'
        gradientUnits='userSpaceOnUse'
        x1={35.248}
        y1={-182.115}
        x2={34.653}
        y2={11.637}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#007aff' stopOpacity={0} />
        <stop offset={0.5} stopColor='#009cff' stopOpacity={0.5} />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M40.47 98.88l-4.51-2.58a.195.195 0 00-.23 0l-5.88 3.39a.11.11 0 000 .08c0 .35-.27 61.84 0 61.99-.01.03-.01.05 0 .07l4.51 2.58c.07.05.16.05.23 0l5.88-3.39c.03-.02.05-.05.05-.07v-62c-.01-.03-.03-.06-.05-.07z'
        fill='url(#banner_svg__SVGID_93_)'
      />
      <linearGradient
        id='banner_svg__SVGID_94_'
        gradientUnits='userSpaceOnUse'
        x1={35.379}
        y1={-183.818}
        x2={34.288}
        y2={-83.554}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#007aff' stopOpacity={0} />
        <stop offset={0.5} stopColor='#009cff' stopOpacity={0.5} />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M37.57 103.7l-2.1-1.23a.108.108 0 00-.11 0l-2.7 1.57v57.49l2.05 1.19c.04.02.07.02.11 0l2.7-1.56c.01 0 .05-57.27.05-57.46z'
        opacity={0.5}
        fill='url(#banner_svg__SVGID_94_)'
      />
    </g>
    <text
      transform='rotate(30.609 -323.12 402.259)'
      fontSize={23.431}
      fontFamily='SourceHanSansCN-Medium-GBpc-EUC-H'
      fill='#fff'
    >
      {'SDK'}
    </text>
  </svg>
)

export default SvgBanner
