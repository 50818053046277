import React from 'react'

const SvgFeature5 = props => (
  <svg viewBox='0 0 50 50' {...props}>
    <path
      d='M25.98 1.04c-.68.6-1.3 1.26-1.87 1.97C12.19 3.27 2.61 13.02 2.61 25c0 12.15 9.85 22 22 22 11.98 0 21.73-9.58 21.99-21.5.71-.57 1.37-1.19 1.97-1.87.03.45.04.91.04 1.37 0 13.25-10.75 24-24 24s-24-10.75-24-24 10.75-24 24-24c.46 0 .92.01 1.37.04z'
      fill='#334966'
    />
    <circle cx={24.61} cy={25} r={12} fill='#fd5c1f' />
    <path
      d='M5.19 37.33a23.15 23.15 0 007.07 7.07l20.93-20.93.56.15A10.994 10.994 0 0047.61 13c0-1.42-.27-2.79-.78-4.07l-5.9 6.19-5.15-1.29-1.28-5.15 6.19-5.9C39.4 2.27 38.03 2 36.61 2c-6.08 0-11 4.92-11 11 0 .97.13 1.92.37 2.84l.15.56L5.19 37.33z'
      fill='none'
      stroke='#334966'
      strokeWidth={2}
    />
  </svg>
)

export default SvgFeature5
