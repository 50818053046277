import React from 'react'

const SvgApp2 = props => (
  <svg id='app2_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 122 86' xmlSpace='preserve' {...props}>
    <style>{'.app2_svg__st9{fill:#fd5c1f}'}</style>
    <linearGradient id='app2_svg__SVGID_1_' gradientUnits='userSpaceOnUse' x1={120} y1={14.557} x2={2} y2={14.557}>
      <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
      <stop offset={0.995} stopColor='#252a3a' />
    </linearGradient>
    <path fill='none' stroke='url(#app2_svg__SVGID_1_)' strokeWidth={2} strokeMiterlimit={10} d='M2 14.56h118' />
    <linearGradient id='app2_svg__SVGID_2_' gradientUnits='userSpaceOnUse' x1={9} y1={95} x2={113} y2={-9}>
      <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
      <stop offset={0.995} stopColor='#252a3a' />
    </linearGradient>
    <path fill='none' stroke='url(#app2_svg__SVGID_2_)' strokeWidth={2} strokeMiterlimit={10} d='M1 1h120v84H1z' />
    <linearGradient id='app2_svg__SVGID_3_' gradientUnits='userSpaceOnUse' x1={5.849} y1={9.557} x2={9.849} y2={5.557}>
      <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
      <stop offset={0.995} stopColor='#252a3a' />
    </linearGradient>
    <path fill='url(#app2_svg__SVGID_3_)' d='M5.85 5.56h4v4h-4z' />
    <linearGradient
      id='app2_svg__SVGID_4_'
      gradientUnits='userSpaceOnUse'
      x1={13.849}
      y1={9.557}
      x2={17.849}
      y2={5.557}
    >
      <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
      <stop offset={0.995} stopColor='#252a3a' />
    </linearGradient>
    <path fill='url(#app2_svg__SVGID_4_)' d='M13.85 5.56h4v4h-4z' />
    <linearGradient
      id='app2_svg__SVGID_5_'
      gradientUnits='userSpaceOnUse'
      x1={21.849}
      y1={9.557}
      x2={25.849}
      y2={5.557}
    >
      <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
      <stop offset={0.995} stopColor='#252a3a' />
    </linearGradient>
    <path fill='url(#app2_svg__SVGID_5_)' d='M21.85 5.56h4v4h-4z' />
    <g>
      <linearGradient
        id='app2_svg__SVGID_6_'
        gradientUnits='userSpaceOnUse'
        x1={62.167}
        y1={42.557}
        x2={82.167}
        y2={42.557}
      >
        <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
        <stop offset={0.995} stopColor='#252a3a' />
      </linearGradient>
      <path fill='none' stroke='url(#app2_svg__SVGID_6_)' strokeWidth={2} strokeMiterlimit={10} d='M62.17 42.56h20' />
      <linearGradient
        id='app2_svg__SVGID_7_'
        gradientUnits='userSpaceOnUse'
        x1={62.167}
        y1={50.057}
        x2={82.167}
        y2={50.057}
      >
        <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
        <stop offset={0.995} stopColor='#252a3a' />
      </linearGradient>
      <path fill='none' stroke='url(#app2_svg__SVGID_7_)' strokeWidth={2} strokeMiterlimit={10} d='M62.17 50.06h20' />
      <linearGradient
        id='app2_svg__SVGID_8_'
        gradientUnits='userSpaceOnUse'
        x1={62.167}
        y1={57.557}
        x2={82.167}
        y2={57.557}
      >
        <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
        <stop offset={0.995} stopColor='#252a3a' />
      </linearGradient>
      <path fill='none' stroke='url(#app2_svg__SVGID_8_)' strokeWidth={2} strokeMiterlimit={10} d='M62.17 57.56h20' />
      <path
        id='app2_svg__Combined-Shape_11_'
        d='M101.83 40.56v6.21a5.497 5.497 0 00-7 5.29 5.497 5.497 0 007 5.29v4.32H82.84V49.56v-9h4.21a5.497 5.497 0 015.29-7 5.497 5.497 0 015.29 7h4.2z'
        fill='none'
        stroke='#fd5c1f'
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        className='app2_svg__st9'
        d='M42.17 71.16c-12.15 0-22-9.85-22-22s9.85-22 22-22 22 9.85 22 22c0 12.16-9.85 22-22 22zm0-2c11.04 0 20-8.96 20-20s-8.96-20-20-20-20 8.95-20 20 8.95 20 20 20zm0-1.14'
      />
      <path
        className='app2_svg__st9'
        d='M38.83 45.83l-3.33 10 10-3.33 2.22 2.22-16.67 5.55 5.56-16.67 2.22 2.23zm0 0l-2.22-2.22 16.67-5.55-5.55 16.67-2.23-2.23 3.33-10-10 3.33zm0 0'
      />
      <path
        className='app2_svg__st9'
        d='M40.6 49.16c0 .56.3 1.08.79 1.36.49.28 1.09.28 1.57 0a1.567 1.567 0 000-2.72c-.49-.28-1.09-.28-1.57 0-.5.28-.79.8-.79 1.36zm0 0'
      />
    </g>
  </svg>
)

export default SvgApp2
