import React from 'react'

const SvgApp5 = props => (
  <svg id='app5_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 122 86' xmlSpace='preserve' {...props}>
    <style>{'.app5_svg__st5{fill:none;stroke:#fd5c1f;stroke-width:2;stroke-miterlimit:10}'}</style>
    <linearGradient id='app5_svg__SVGID_1_' gradientUnits='userSpaceOnUse' x1={120} y1={14.557} x2={2} y2={14.557}>
      <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
      <stop offset={0.995} stopColor='#252a3a' />
    </linearGradient>
    <path fill='none' stroke='url(#app5_svg__SVGID_1_)' strokeWidth={2} strokeMiterlimit={10} d='M2 14.56h118' />
    <linearGradient id='app5_svg__SVGID_2_' gradientUnits='userSpaceOnUse' x1={9} y1={95} x2={113} y2={-9}>
      <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
      <stop offset={0.995} stopColor='#252a3a' />
    </linearGradient>
    <path fill='none' stroke='url(#app5_svg__SVGID_2_)' strokeWidth={2} strokeMiterlimit={10} d='M1 1h120v84H1z' />
    <linearGradient id='app5_svg__SVGID_3_' gradientUnits='userSpaceOnUse' x1={5.849} y1={9.557} x2={9.849} y2={5.557}>
      <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
      <stop offset={0.995} stopColor='#252a3a' />
    </linearGradient>
    <path fill='url(#app5_svg__SVGID_3_)' d='M5.85 5.56h4v4h-4z' />
    <linearGradient
      id='app5_svg__SVGID_4_'
      gradientUnits='userSpaceOnUse'
      x1={13.849}
      y1={9.557}
      x2={17.849}
      y2={5.557}
    >
      <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
      <stop offset={0.995} stopColor='#252a3a' />
    </linearGradient>
    <path fill='url(#app5_svg__SVGID_4_)' d='M13.85 5.56h4v4h-4z' />
    <linearGradient
      id='app5_svg__SVGID_5_'
      gradientUnits='userSpaceOnUse'
      x1={21.849}
      y1={9.557}
      x2={25.849}
      y2={5.557}
    >
      <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
      <stop offset={0.995} stopColor='#252a3a' />
    </linearGradient>
    <path fill='url(#app5_svg__SVGID_5_)' d='M21.85 5.56h4v4h-4z' />
    <path className='app5_svg__st5' d='M46 26.56h30v48H46z' />
    <path className='app5_svg__st5' d='M50 30.56h22v31H50z' />
    <path fill='#fd5c1f' d='M59 66.56h4v4h-4z' />
  </svg>
)

export default SvgApp5
