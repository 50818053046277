import React from 'react'
import intl from 'react-intl-universal'
import { CommonBanner, InviteBanner } from '../../components/banners'
import { CommonTitle } from '../../components/titles'
import { Swiper, Button, Wrapper, H4, Link } from '@raysync/common/components'
import DocumentCard from '../../components/document-cards'
import { LOCALES, Navigation, LocaleService } from '@raysync/common/services'

import Layout from '../../components/layout'
import {
  ApplicationSection,
  Application,
  FeatureSection,
  ButtonContainer,
  ArchSection,
  ExampleSection,
  ExampleContent,
  ExampleContainer,
  ApplicationContainer,
} from './product-sdk.atoms'
import List from '../../components/list'

import Client from '../../images/products/sdk/client.png'
import Logo from '../../images/products/sdk/renderbus_logo.png'
import ClientEn from '../../images/products/sdk/fox_example.png'
import LogoEn from '../../images/products/sdk/fox_logo.png'

import App1 from '@raysync/common/components/svg/products/sdk/app1'
import App2 from '@raysync/common/components/svg/products/sdk/app2'
import App3 from '@raysync/common/components/svg/products/sdk/app3'
import App4 from '@raysync/common/components/svg/products/sdk/app4'
import App5 from '@raysync/common/components/svg/products/sdk/app5'
import Banner from '@raysync/common/components/svg/products/sdk/banner'

import Feature1 from '@raysync/common/components/svg/products/sdk/feature1'
import Feature2 from '@raysync/common/components/svg/products/sdk/feature2'
import Feature3 from '@raysync/common/components/svg/products/sdk/feature3'
import Feature4 from '@raysync/common/components/svg/products/sdk/feature4'
import Feature5 from '@raysync/common/components/svg/products/sdk/feature5'
import Feature6 from '@raysync/common/components/svg/products/sdk/feature6'

import Arch from '@raysync/common/components/svg/products/sdk/arch'
import ArchEn from './sdk.svg'

const clientPic = {
  [LOCALES.ZH]: Client,
  [LOCALES.EN]: ClientEn,
  [LOCALES.JA]: ClientEn,
}[LocaleService.currentLocale]

const logoPic = {
  [LOCALES.ZH]: Logo,
  [LOCALES.EN]: LogoEn,
  [LOCALES.JA]: LogoEn,
}[LocaleService.currentLocale]

class ProductSDK extends React.PureComponent {
  state = {
    appList: [
      { name: intl.get('product2.application1'), svg: <App1 /> },
      { name: intl.get('product2.application2'), svg: <App2 /> },
      { name: intl.get('product2.application3'), svg: <App3 /> },
      { name: intl.get('product2.application4'), svg: <App4 /> },
      { name: intl.get('product2.application5'), svg: <App5 /> },
    ],
    featureList: [
      {
        title: intl.get('product2.feature.item1.title'),
        desc: intl.get('product2.feature.item1.desc'),
        svg: <Feature1 />,
      },
      {
        title: intl.get('product2.feature.item2.title'),
        desc: intl.get('product2.feature.item2.desc'),
        svg: <Feature2 />,
      },
      {
        title: intl.get('product2.feature.item3.title'),
        desc: intl.get('product2.feature.item3.desc'),
        svg: <Feature3 />,
      },
      {
        title: intl.get('product2.feature.item4.title'),
        desc: intl.get('product2.feature.item4.desc'),
        svg: <Feature4 />,
      },
      {
        title: intl.get('product2.feature.item5.title'),
        desc: intl.get('product2.feature.item5.desc'),
        svg: <Feature5 />,
      },
      {
        title: intl.get('product2.feature.item6.title'),
        desc: intl.get('product2.feature.item6.desc'),
        svg: <Feature6 />,
      },
    ],
  }
  render() {
    const { location } = this.props
    const { appList, featureList } = this.state
    const ArchPic = {
      [LOCALES.ZH]: <Arch />,
      [LOCALES.EN]: <img src={ArchEn} alt='Raysync SDK' />,
      [LOCALES.JA]: <img src={ArchEn} alt='Raysync SDK' />,
    }[intl.options.currentLocale]
    return (
      <Layout pageType='product2' location={location}>
        <Swiper>
          <CommonBanner type='product2' pic={<Banner />} right={35} />
        </Swiper>
        <ApplicationSection>
          <CommonTitle>{intl.get('product2.application.title')}</CommonTitle>
          <ApplicationContainer halign='space-between'>
            {appList.map((l, i) => (
              <Application key={i}>
                {l.svg}
                <p>{l.name}</p>
              </Application>
            ))}
          </ApplicationContainer>
        </ApplicationSection>
        <FeatureSection>
          <Wrapper>
            <CommonTitle bottom={44}>{intl.get('product2.feature.title')}</CommonTitle>
            <List data={featureList} />
            <ButtonContainer>
              <Button variant='outlined' onClick={() => Navigation.toApply({ type: 'prompt', pageType: 'product2' })}>
                {intl.get('product2.feature.button1')}
              </Button>
              <Button onClick={() => Navigation.toApply({ type: 'apply', pageType: 'product2' })}>
                {intl.get('product2.feature.button2')}
              </Button>
            </ButtonContainer>
          </Wrapper>
        </FeatureSection>
        <ArchSection>
          <CommonTitle>{intl.get('product2.deploy.title')}</CommonTitle>
          {ArchPic}
        </ArchSection>
        <ExampleSection>
          <Wrapper>
            <CommonTitle bottom={45}>{intl.get('product2.example.title')}</CommonTitle>
            <ExampleContainer>
              <div>
                <img src={clientPic} alt='client pic' />
              </div>
              <ExampleContent>
                <img src={logoPic} alt='Renderbus Logo' />
                <H4>{intl.get('product2.example.desc1')}</H4>
                <p>{intl.get('product2.example.desc2')}</p>
                <p>{intl.get('product2.example.desc3')}</p>
              </ExampleContent>
            </ExampleContainer>
            <ButtonContainer>
              <Link to={'/example'}>
                <Button variant='outlined' style={{ margin: '0 auto' }}>
                  {intl.get('product2.example.button')}
                </Button>
              </Link>
            </ButtonContainer>
          </Wrapper>
        </ExampleSection>
        <Wrapper style={{ paddingTop: '68px', paddingBottom: '68px' }}>
          <CommonTitle>{intl.get('app.document.title')}</CommonTitle>
          <DocumentCard type='product2' />
        </Wrapper>
        <Swiper>
          <InviteBanner type='product2' />
        </Swiper>
      </Layout>
    )
  }
}

export default ProductSDK
