import React from 'react'

const SvgApp3 = props => (
  <svg id='app3_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 122 86' xmlSpace='preserve' {...props}>
    <style>{'.app3_svg__st0{fill:#fd5c1f}'}</style>
    <path id='app3_svg__Rectangle-24-Copy' className='app3_svg__st0' d='M46 25.22h30v48H46v-48zm2 2v44h26v-44H48z' />
    <path id='app3_svg__Rectangle-38-Copy-2' className='app3_svg__st0' d='M46 63.22h30v2H46z' />
    <path id='app3_svg__Rectangle-38-Copy-3' className='app3_svg__st0' d='M56 67.22h10v2H56z' />
    <path id='app3_svg__Rectangle-38-Copy-4' className='app3_svg__st0' d='M46 31.22h30v2H46z' />
    <path id='app3_svg__Rectangle-38-Copy-4_2_' className='app3_svg__st0' d='M38 37.22h2v24h-2z' />
    <path id='app3_svg__Rectangle-38-Copy-4_3_' className='app3_svg__st0' d='M82 37.22h2v24h-2z' />
    <g>
      <linearGradient id='app3_svg__SVGID_1_' gradientUnits='userSpaceOnUse' x1={120} y1={14.557} x2={2} y2={14.557}>
        <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
        <stop offset={0.995} stopColor='#252a3a' />
      </linearGradient>
      <path fill='url(#app3_svg__SVGID_1_)' d='M2 13.56h118v2H2z' />
      <linearGradient id='app3_svg__SVGID_2_' gradientUnits='userSpaceOnUse' x1={9} y1={95} x2={113} y2={-9}>
        <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
        <stop offset={0.995} stopColor='#252a3a' />
      </linearGradient>
      <path d='M122 86H0V0h122v86zM2 84h118V2H2v82z' fill='url(#app3_svg__SVGID_2_)' />
      <linearGradient
        id='app3_svg__SVGID_3_'
        gradientUnits='userSpaceOnUse'
        x1={5.849}
        y1={9.557}
        x2={9.849}
        y2={5.557}
      >
        <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
        <stop offset={0.995} stopColor='#252a3a' />
      </linearGradient>
      <path fill='url(#app3_svg__SVGID_3_)' d='M5.85 5.56h4v4h-4z' />
      <linearGradient
        id='app3_svg__SVGID_4_'
        gradientUnits='userSpaceOnUse'
        x1={13.849}
        y1={9.557}
        x2={17.849}
        y2={5.557}
      >
        <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
        <stop offset={0.995} stopColor='#252a3a' />
      </linearGradient>
      <path fill='url(#app3_svg__SVGID_4_)' d='M13.85 5.56h4v4h-4z' />
      <linearGradient
        id='app3_svg__SVGID_5_'
        gradientUnits='userSpaceOnUse'
        x1={21.849}
        y1={9.557}
        x2={25.849}
        y2={5.557}
      >
        <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
        <stop offset={0.995} stopColor='#252a3a' />
      </linearGradient>
      <path fill='url(#app3_svg__SVGID_5_)' d='M21.85 5.56h4v4h-4z' />
    </g>
    <g>
      <linearGradient id='app3_svg__SVGID_6_' gradientUnits='userSpaceOnUse' x1={120} y1={14.557} x2={2} y2={14.557}>
        <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
        <stop offset={0.995} stopColor='#252a3a' />
      </linearGradient>
      <path fill='none' stroke='url(#app3_svg__SVGID_6_)' strokeWidth={2} strokeMiterlimit={10} d='M2 14.56h118' />
      <linearGradient id='app3_svg__SVGID_7_' gradientUnits='userSpaceOnUse' x1={9} y1={95} x2={113} y2={-9}>
        <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
        <stop offset={0.995} stopColor='#252a3a' />
      </linearGradient>
      <path fill='none' stroke='url(#app3_svg__SVGID_7_)' strokeWidth={2} strokeMiterlimit={10} d='M1 1h120v84H1z' />
      <linearGradient
        id='app3_svg__SVGID_8_'
        gradientUnits='userSpaceOnUse'
        x1={5.849}
        y1={9.557}
        x2={9.849}
        y2={5.557}
      >
        <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
        <stop offset={0.995} stopColor='#252a3a' />
      </linearGradient>
      <path fill='url(#app3_svg__SVGID_8_)' d='M5.85 5.56h4v4h-4z' />
      <linearGradient
        id='app3_svg__SVGID_9_'
        gradientUnits='userSpaceOnUse'
        x1={13.849}
        y1={9.557}
        x2={17.849}
        y2={5.557}
      >
        <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
        <stop offset={0.995} stopColor='#252a3a' />
      </linearGradient>
      <path fill='url(#app3_svg__SVGID_9_)' d='M13.85 5.56h4v4h-4z' />
      <linearGradient
        id='app3_svg__SVGID_10_'
        gradientUnits='userSpaceOnUse'
        x1={21.849}
        y1={9.557}
        x2={25.849}
        y2={5.557}
      >
        <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
        <stop offset={0.995} stopColor='#252a3a' />
      </linearGradient>
      <path fill='url(#app3_svg__SVGID_10_)' d='M21.85 5.56h4v4h-4z' />
    </g>
  </svg>
)

export default SvgApp3
