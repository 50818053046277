import React from 'react'

const SvgApp1 = props => (
  <svg id='app1_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 122 86' xmlSpace='preserve' {...props}>
    <style>{'.app1_svg__st5{fill:#fd5c1f}'}</style>
    <linearGradient id='app1_svg__SVGID_1_' gradientUnits='userSpaceOnUse' x1={120} y1={14.557} x2={2} y2={14.557}>
      <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
      <stop offset={0.995} stopColor='#252a3a' />
    </linearGradient>
    <path fill='none' stroke='url(#app1_svg__SVGID_1_)' strokeWidth={2} strokeMiterlimit={10} d='M2 14.56h118' />
    <linearGradient id='app1_svg__SVGID_2_' gradientUnits='userSpaceOnUse' x1={9} y1={95} x2={113} y2={-9}>
      <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
      <stop offset={0.995} stopColor='#252a3a' />
    </linearGradient>
    <path fill='none' stroke='url(#app1_svg__SVGID_2_)' strokeWidth={2} strokeMiterlimit={10} d='M1 1h120v84H1z' />
    <linearGradient id='app1_svg__SVGID_3_' gradientUnits='userSpaceOnUse' x1={5.849} y1={9.557} x2={9.849} y2={5.557}>
      <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
      <stop offset={0.995} stopColor='#252a3a' />
    </linearGradient>
    <path fill='url(#app1_svg__SVGID_3_)' d='M5.85 5.56h4v4h-4z' />
    <linearGradient
      id='app1_svg__SVGID_4_'
      gradientUnits='userSpaceOnUse'
      x1={13.849}
      y1={9.557}
      x2={17.849}
      y2={5.557}
    >
      <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
      <stop offset={0.995} stopColor='#252a3a' />
    </linearGradient>
    <path fill='url(#app1_svg__SVGID_4_)' d='M13.85 5.56h4v4h-4z' />
    <linearGradient
      id='app1_svg__SVGID_5_'
      gradientUnits='userSpaceOnUse'
      x1={21.849}
      y1={9.557}
      x2={25.849}
      y2={5.557}
    >
      <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
      <stop offset={0.995} stopColor='#252a3a' />
    </linearGradient>
    <path fill='url(#app1_svg__SVGID_5_)' d='M21.85 5.56h4v4h-4z' />
    <g>
      <path
        className='app1_svg__st5'
        d='M60.97 70.96c-5.65 0-10.06-10.12-10.06-23s4.41-23 10.06-23 10.06 10.12 10.06 23c-.01 12.88-4.47 23-10.06 23zm0-44.59c-4.69 0-8.65 9.89-8.65 21.58 0 11.69 3.95 21.58 8.65 21.58 4.69 0 8.65-9.89 8.65-21.58-.01-11.69-3.97-21.58-8.65-21.58zm0 0'
      />
      <path
        className='app1_svg__st5'
        d='M73.85 62.42c-4.97 0-11.64-2.15-17.91-5.77C44.75 50.2 38.2 41.34 41.02 36.42c1.13-1.92 3.56-2.99 7.01-2.99 4.97 0 11.64 2.15 17.91 5.77 11.19 6.5 17.74 15.37 14.92 20.29-1.14 1.91-3.51 2.93-7.01 2.93zM48.02 34.91c-2.03 0-4.69.4-5.82 2.26-2.32 4.07 4.24 12.43 14.41 18.31 6.05 3.5 12.49 5.59 17.23 5.59 2.03 0 4.69-.4 5.82-2.26 2.32-4.07-4.24-12.43-14.41-18.31-6.04-3.51-12.48-5.59-17.23-5.59zm0 0'
      />
      <path
        className='app1_svg__st5'
        d='M48.02 62.42c-3.44 0-5.87-1.02-7.01-2.99-2.83-4.86 3.73-13.73 14.92-20.17 6.27-3.62 13-5.77 17.91-5.77 3.44 0 5.87 1.02 7.01 2.99 2.83 4.86-3.73 13.73-14.92 20.17-6.21 3.63-12.93 5.77-17.91 5.77zm25.83-27.51c-4.75 0-11.13 2.09-17.23 5.59-10.12 5.82-16.73 14.18-14.36 18.25 1.07 1.87 3.73 2.26 5.82 2.26 4.75 0 11.13-2.09 17.23-5.59 10.12-5.83 16.67-14.19 14.35-18.26-1.07-1.86-3.78-2.25-5.81-2.25zm0 0'
      />
      <path
        className='app1_svg__st5'
        d='M64.81 47.96c0 2.15-1.75 3.9-3.9 3.9s-3.9-1.75-3.9-3.9 1.75-3.84 3.9-3.84c2.2-.06 3.9 1.69 3.9 3.84zM49.49 34.4a2.27 2.27 0 01-2.26 2.26 2.27 2.27 0 01-2.26-2.26 2.27 2.27 0 012.26-2.26c1.25-.01 2.26 1.02 2.26 2.26zM81.64 44a2.27 2.27 0 01-2.26 2.26A2.27 2.27 0 0177.12 44a2.27 2.27 0 012.26-2.26c1.25 0 2.26 1.02 2.26 2.26zM58.93 67.73a2.27 2.27 0 01-2.26 2.26 2.27 2.27 0 01-2.26-2.26 2.27 2.27 0 012.26-2.26 2.27 2.27 0 012.26 2.26zm0 0'
      />
    </g>
  </svg>
)

export default SvgApp1
