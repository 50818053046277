import React from 'react'

const SvgApp4 = props => (
  <svg viewBox='0 0 122 86' {...props}>
    <linearGradient id='app4_svg__a' gradientUnits='userSpaceOnUse' x1={120.008} y1={14.557} x2={2.008} y2={14.557}>
      <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
      <stop offset={0.995} stopColor='#252a3a' />
    </linearGradient>
    <path fill='none' stroke='url(#app4_svg__a)' strokeWidth={2} strokeMiterlimit={10} d='M2.01 14.56h118' />
    <linearGradient id='app4_svg__b' gradientUnits='userSpaceOnUse' x1={9.008} y1={95} x2={113.008} y2={-9}>
      <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
      <stop offset={0.995} stopColor='#252a3a' />
    </linearGradient>
    <path fill='none' stroke='url(#app4_svg__b)' strokeWidth={2} strokeMiterlimit={10} d='M1.01 1h120v84h-120z' />
    <linearGradient id='app4_svg__c' gradientUnits='userSpaceOnUse' x1={5.857} y1={9.557} x2={9.857} y2={5.557}>
      <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
      <stop offset={0.995} stopColor='#252a3a' />
    </linearGradient>
    <path fill='url(#app4_svg__c)' d='M5.86 5.56h4v4h-4z' />
    <linearGradient id='app4_svg__d' gradientUnits='userSpaceOnUse' x1={13.857} y1={9.557} x2={17.857} y2={5.557}>
      <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
      <stop offset={0.995} stopColor='#252a3a' />
    </linearGradient>
    <path fill='url(#app4_svg__d)' d='M13.86 5.56h4v4h-4z' />
    <linearGradient id='app4_svg__e' gradientUnits='userSpaceOnUse' x1={21.857} y1={9.557} x2={25.857} y2={5.557}>
      <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
      <stop offset={0.995} stopColor='#252a3a' />
    </linearGradient>
    <path fill='url(#app4_svg__e)' d='M21.86 5.56h4v4h-4z' />
    <path
      d='M70.49 50.58l-7.16-19.63c-.56-1.53-1.53-2.96-2.76-4.09-.2-.2-.51-.31-.82-.31-.61 0-1.12.46-1.18 1.07-.05.46-.1.87-.1 1.33 0 1.23.2 2.4.61 3.58l.1.26c-1.12-.1-2.25.51-2.66 1.64l-5.88 16.15H39.41v7.92h8.33l-2.15 5.88-.05.1-.2 8.08 5.01-6.29 2.86-7.77h15.44l1.43 3.99v.15c-.1 2.91 1.28 5.72 3.63 7.46l2.1 1.58.61-2.56c.66-2.86-.1-5.83-2.04-8.02l-.1-.1-.92-2.45h9.25v-7.97H70.49zm2.76 11.5c1.64 1.79 2.25 4.29 1.69 6.64l-.05.31-.26-.2c-1.94-1.43-3.07-3.73-2.96-6.13v-.46l-11.1-30.27c-.36-.97-.51-1.99-.51-3.02v-.46c.82.87 1.43 1.89 1.84 3.02l11.09 30.26.26.31zM48.97 65.5L46.98 68l.1-3.17 10.94-29.9c.2-.51.77-.77 1.28-.61.41.15.66.51.66.92 0 .1 0 .2-.05.36L48.97 65.5zm11.91-28.11l4.86 13.19h-9.66l4.8-13.19zM40.95 56.97v-4.86h9.15l-1.79 4.86h-7.36zm12.77 0l1.79-4.86H66.3l1.79 4.86H53.72zm27.4 0h-8.28l-1.79-4.86h10.07v4.86z'
      fill='#fd5c1f'
    />
  </svg>
)

export default SvgApp4
