import React from 'react'

const SvgFeature6 = props => (
  <svg id='feature6_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 50 50' xmlSpace='preserve' {...props}>
    <style>{'.feature6_svg__st2{fill:#334966}'}</style>
    <path
      d='M2 3.08v42h20.3l2.7 1.84 2.7-1.84H48v-42H28.3L25 5.33l-3.3-2.26H2z'
      fill='none'
      stroke='#334966'
      strokeWidth={2}
      strokeMiterlimit={10}
    />
    <path d='M25 13.98l2.81-2.9H40v25.1l-2.81 2.9H25v-25.1z' fill='#fd5c1f' />
    <path className='feature6_svg__st2' d='M8 24.08h23v2H8zM8 32.08h23v2H8zM8 16.08h12v2H8z' />
  </svg>
)

export default SvgFeature6
