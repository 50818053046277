import React from 'react'

const SvgFeature4 = props => (
  <svg id='feature4_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 50 50' xmlSpace='preserve' {...props}>
    <style>
      {
        '.feature4_svg__st0{fill:#334966}.feature4_svg__st1{fill:#fd5c1f}.feature4_svg__st2{fill:none;stroke:#334966;stroke-width:2}'
      }
    </style>
    <g id='feature4_svg__\u5B8C\u5168\u6258\u7BA1'>
      <path id='feature4_svg__Rectangle-16' className='feature4_svg__st0' d='M3 6.39v32h44v-32H3zm-2-2h48v36H1v-36z' />
      <path id='feature4_svg__Rectangle-17_1_' className='feature4_svg__st0' d='M13 44.39h24v2H13z' />
      <path id='feature4_svg__Rectangle-73_1_' className='feature4_svg__st0' d='M21 38.39h2v8h-2z' />
      <path id='feature4_svg__Rectangle-73-Copy' className='feature4_svg__st0' d='M27 38.39h2v8h-2z' />
      <path id='feature4_svg__Rectangle-74' className='feature4_svg__st1' d='M13 15.39h8v18h-8z' />
      <path id='feature4_svg__Rectangle-74-Copy' className='feature4_svg__st1' d='M29 11.39h8v22h-8z' />
      <path id='feature4_svg__Path-5' className='feature4_svg__st2' d='M10 31.39l12-12 11 2 7-7' />
      <path id='feature4_svg__Path-5-Copy' className='feature4_svg__st2' d='M10 19.39l15 12 7.85-4.12L40 28.39' />
    </g>
  </svg>
)

export default SvgFeature4
